import {useEffect, useState } from "react";
import Header from "./Header/header";
import SideBar from "./SideBar/sideBar";
import "./layout.css";
import ManageDivisions from "../ManageDivisions";
import ManageOrganizations from "../ManageOrganisations";
import ManageUser from "../ManageUser/manageUser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import MyBidsInternal from "../MyBidsInternal";
import { useDispatch, useSelector } from "react-redux";
import { setColorTheme, setToNavigateMenu } from "../../redux/actions/actions";
import { selectThemeColor, selectToNavigate } from "../../redux/selectors/selectors";
import { SubmenuEnum } from "../../utils/enum";

const LayOut = () => {

  const [updateToggle, setUpdateToggle] = useState<boolean>(false);
  const [isBack, setIsBack] = useState(false);
  const [role, setRole] = useState<string>(localStorage.getItem('role') || "")

  const dispatch = useDispatch();
  const color = useSelector(selectThemeColor);
  const menuName = useSelector(selectToNavigate)

  const onClickToggle = () => {
    setUpdateToggle((prev) => !prev );
  };

  const selectedMenu = (name: string) => {
    dispatch(setToNavigateMenu(name));
  };

  useEffect(() => {
    dispatch(setColorTheme(role));
    dispatch(setToNavigateMenu(SubmenuEnum.MY_BIDS));
    return setRole(localStorage.getItem("role") || "");
  }, [role]);

  const [subMenu, setSubMenu] = useState("");

  const getSubMenu = (menu :string) =>{
    setSubMenu(menu)
  }
  const getMenuName = (menuName: string) => {
    if (
      menuName !== SubmenuEnum.MY_BIDS &&
      menuName !== SubmenuEnum.POST_BIDS &&
      menuName !== SubmenuEnum.EDIT_PROJECT &&
      menuName !== SubmenuEnum.INVITED_BIDS &&
      menuName !== SubmenuEnum.BID_OPPORTUNITIES_NETWORK
    ) {
      return <h5>{menuName}</h5>;
    } else {
      return <></>;
    }
  };

  const renderMenu = (key: string) => {
    switch (key) {
      case "My Bids":
        return <MyBidsInternal/>;
      case "Manage Trades":
        return <ManageDivisions />;
      case "Manage Organizations":
        return <ManageOrganizations setBackButton={setIsBack} isBack={isBack} getSubMenu={getSubMenu} />;
      case "Manage Users":
        return <ManageUser setBackButton={setIsBack} isBack={isBack} getSubMenu={getSubMenu} />;
      default:
        return <></>;
    }
  };
  
  const renderOrgMenu = (key: string) => {
    console.log('key', key)
    switch (key) {
      case "Manage Users":
        return <ManageUser setBackButton={setIsBack} isBack={isBack} getSubMenu={getSubMenu} />;
        case "Manage Trades":
        return <ManageDivisions />;
      case "Manage Organizations":
        return <ManageOrganizations setBackButton={setIsBack} isBack={isBack} getSubMenu={getSubMenu} />;
      default:
        return <MyBidsInternal/>;
    }
  };

  useEffect(()=>{
    setIsBack(false)
    setSubMenu("")
  },[menuName]);

  return (
    <>
      <div className="container-fluid g-0" style={{ overflow: "hidden" }}>
        <Header
          onClickToggle={onClickToggle}
          showMenuBar={role === null}
          onMenuClick={selectedMenu}
        />
        {role === null ? (
          <main style={{ overflow: "hidden" }}>
            <div className="d-flex">
              <SideBar
                key={"sideBar"}
                toggle={updateToggle}
                onMenuClick={selectedMenu}
              />
              <div className="display-area-maximize mt-3">
                <div className="sub-header display-5 p-3 px-5">
                  <div className="d-flex border-bottom py-2 align-items-center ">
                    {isBack ? (
                      <>
                        <FontAwesomeIcon
                          className="mx-1 pointer size-xl"
                          icon={faArrowLeft}
                          size="2xs"
                          style={{ color: color.darkColor }}
                          onClick={() => {
                            setIsBack(false);
                            setSubMenu("");
                          }}
                        />
                        <h4 className="m-0 size-xl">
                          {menuName} {subMenu}
                        </h4>
                      </>
                    ) : (
                      <h4 className="m-0 size-xl">{menuName}</h4>
                    )}
                  </div>
                </div>
                <section
                  className="container"
                  style={{ height: "80vh", overflowY: "scroll" }}
                >
                  {renderMenu(menuName)}
                </section>
              </div>
            </div>
          </main>
        ) : (
          <main className="mt-4">
            <div className="container-fluid" style={{ height: "80vh" }}>
              <div className="row">
                {
                  getMenuName(menuName)
                }
              
              </div>
              {renderOrgMenu(menuName)}
            </div>
          </main>
        )}
      </div>
    </>
  );
};
export default LayOut;