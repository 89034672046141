import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Checkbox,
  Stack,
  Chip,
  Link,
  TextField,
} from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import { useEffect, useState } from "react";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import {
  getAddedSubsForProject,
  getAllSubsMailListForProject,
} from "../../utils/api";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataUtil } from "../../utils/dataUtils";
import { CustomTable } from "../DataTable";
import { useSelector } from "react-redux";
import { selectProjectId } from "../../redux/selectors/selectors";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  disableScrollLock: true,
};

interface Props {
  selectedMails: string[];
  handleClose: () => void;
  selectFieldList: string[];
  showAllMails?: boolean;
  handleUpdateNewMails?: (newMail: string | string[]) => void;
  isLoading?: boolean;
  handleClickOnPreview?: (viewMailPreview: boolean) => void;
  handleAddSubMails?: (
    addSubMail: {
      mail: string;
      isSelected: boolean;
    }[]
  ) => void;
  handleSelectMainField: (field : string[]) => void;
  mainSelectField:string[];
}

const InviteSubDropdownField = ({
  selectedMails = [],
  handleUpdateNewMails,
  selectFieldList = [],
  handleSelectMainField,
  mainSelectField
}: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [subMultiMails, setSubMultiMails] = useState<{mail:string,isSelected:boolean}[]>([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [searchItem, setSearchItem] = useState("");
  const [tradeFieldList, setTradeFieldList] = useState(selectFieldList);
  const [tradeSelectedFieldList, setTradeSelectedFieldList] = useState<
    string[]
  >([]);
  const [isExpanded, setIsExpanded] = useState(false);

  const projectId = useSelector(selectProjectId);

  useEffect(() => {
    setTradeFieldList(selectFieldList);
  }, [selectFieldList]);

  const open = Boolean(anchorEl);
  const popOverHeader = [
    {
      id: "cell",
      name: (
         <Checkbox
          className="p-0 ms-1"
          value="All"
          color="secondary"
          checked={isSelectAll}
          onClick={(e: any) => {
            const updatedList = subMultiMails.map((item, _index) => {
              return { ...item, isSelected: e.target.checked };
            });
            setSubMultiMails(updatedList);
            setIsSelectAll(!e.target.checked);
          }}
          onChange={() => {
            setIsSelectAll((prev) => !prev);
          }}
          disabled={subMultiMails.length === 0}
        />
      ),
      align: "left",
    },
    { id: "subMail", name: "Sub Mail", action: "", align: "left" },
  ];

  const handleTradesChipClick = (event: any) => {
    setIsExpanded(true);
    const chipLabel = event.target.closest(".MuiChip-label");
    const trade = tradeFieldList.filter(
      (trade: any) => trade[1] === chipLabel?.textContent
    );
    if (trade[0]) {
      handleFetchAllSubOfTradeCode(event, trade[0][0]);
    } else {
      return;
    }
  };
  const handleAllSubChipClick = (event: any) => {
    setIsExpanded(true);
    const chipLabel = event.target.closest(".MuiChip-label");
    if (chipLabel !== null) {
      handleFetchAddedSubs(event);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleDelete = (value: any) => {
    const temp: string[] = tradeSelectedFieldList.filter(
      (tradeField) => tradeField !== value
    );
    setTradeSelectedFieldList(temp);
    if (temp.length === 0){
      handleSelectMainField([]);
      handleUpdateNewMails!([]);
    }else{
      let updateSubMails : any = [];
      let unSelectedTradeField : any = [];
      let selectedTradeField : any = [];

      unSelectedTradeField = tradeFieldList.filter((data)=> data[1] === value);
      selectedTradeField = tradeFieldList.filter((data)=> data[1] !== value);

      unSelectedTradeField[0][3].forEach((selectedData: any) => {
        selectedTradeField[0][3].forEach((unSelectedData: any) => {
          if (unSelectedData !== selectedData) {
            updateSubMails = selectedMails.filter(
              (data) => data !== selectedData
            );
          }
        });
      });

      handleUpdateNewMails!(updateSubMails)
    }
  };

  const getMenuToolTipTitle = (subMail: string[] | string) => {
    if (!DataUtil.isArrayAndNotEmpty(subMail)) {
      return "This trade does not have any users. Please add a user from the network to the list and try again.";
    }
    return "";
  };

  const renderSelectValue = (selected: any[]) => {
    if (mainSelectField.includes("Trade")) {
      return (
        <div>
          <Stack gap={2} direction="row" flexWrap="wrap">
            {selected.length > 0 &&
              selected.map((value, index) => (
                <Chip
                  key={index}
                  label={value}
                  id={value}
                  onDelete={() => handleDelete(value)}
                  onClick={handleTradesChipClick}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  variant="filled"
                  color="secondary"
                  size="small"
                  clickable={false}
                />
              ))}
          </Stack>
        </div>
      );
    } else {
      return (
        <div>
          <Stack gap={2} direction="row" flexWrap="wrap">
            {selected.length > 0 &&
              selected.map((value, index) => (
                <Chip
                  key={index}
                  label={value}
                  id={value}
                  onDelete={() => {
                    handleUpdateNewMails!([]);
                    handleSelectMainField([]);
                  }}
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                  variant="filled"
                  color="secondary"
                  size="small"
                  clickable={false}
                />
              ))}
          </Stack>
        </div>
      );
    }
  };

  const renderTradeSelect = () => {
    return (
      <div>
        <div
          className="position-sticky top-0 d-flex justify-content-end"
          style={{ overflow: "hidden" }}
        >
          <FormControl className="p-1 bg-light" fullWidth>
            <TextField
              size="small"
              variant="outlined"
              onChange={(event) => {
                setShowClearIcon(event.target.value === "" ? "none" : "flex");
                setSearchItem(() => event.target.value);
                searchSelectedTrades(tradeFieldList, event.target.value);
              }}
              value={searchItem}
              placeholder="Search Trade"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    className="pointer"
                    position="end"
                    style={{ display: showClearIcon }}
                    onClick={() => {
                      resetToInitialdList();
                      setSearchItem("");
                      setShowClearIcon("none");
                    }}
                  >
                    <ClearIcon />
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <Button
            className="col-2 btn p-0"
            size="small"
            onClick={() => {
              handleSelectMainField([]);
              setAnchorEl(null);
              setTradeSelectedFieldList([]);
            }}
          >
            Back
          </Button>
        </div>
        <div style={{ overflowY: "auto" }}>
          {DataUtil.isArrayAndNotEmpty(tradeFieldList) ?
            tradeFieldList.map(
              (field, index) =>
                  <MenuItem
                    className="d-block ms-4 size-xl p-1"
                    key={index}
                    value={field[0]}
                    onClick={() => {
                      if(DataUtil.isArrayAndNotEmpty(field[3])){
                        const isTreadFieldSelected = tradeSelectedFieldList.some(
                          (tradeCode) => tradeCode === field[1]
                        );
                        if (!isTreadFieldSelected) {
                          setTradeSelectedFieldList([
                            ...tradeSelectedFieldList,
                            field[1],
                          ]);
                          handleUpdateNewMails!(field[3])
                        }
                        setIsExpanded(false);
                      }
                    }}
                    title={getMenuToolTipTitle(field[3])}
                  >
                  <Link className="text-decoration-none d-flex  w-100 size-sm" sx={{
                    opacity:DataUtil.isArrayAndNotEmpty(field[3]) ?  "1" : "0.7" 
                  }}>
                      <span className="col-4 p-0">{field[1]}</span>
                      <span className="col-8 d-flex justify-content-start p-0">
                        {field[2]}
                      </span>
                    </Link>
                  </MenuItem>
            ) : <span className="row gx-0 d-flex justify-content-center size-lg opacity-70">No Trade Selected</span>}
        </div>
      </div>
    );
  };

  const renderDefaultSelect = () => {
    return ["All Sub Contractor", "Trade"].map((value, index) => (
      <MenuItem
        className="d-block ms-4 size-xl p-1"
        key={index}
        value={value}
        title="Click Here"
        onClick={(event: any) => {
          handleSelectMainField([value]);
          if (value.includes("All Sub Contractor")) {
            setIsExpanded(false);
            handleFetchAddedSubs(event)
          }
        }}
      >
        <span>{value}</span>
      </MenuItem>
    ));
  };

  const resetToInitialdList = () => setTradeFieldList(selectFieldList);

  const removeSpaces = (str: string) => str.replace(/\s+/g, "");

  const searchSelectedTrades = (_list: any, query: string) => {
    if (!query || query === "") {
      resetToInitialdList();
    } else {
      const filteredNodes = tradeFieldList.filter((item: any) => {
        const found: boolean = item[1]
          .toLowerCase()
          .includes(removeSpaces(query));
        return found;
      });
      setTradeFieldList(filteredNodes);
    }
  };

  const handleFetchAllSubOfTradeCode = (
    event: React.MouseEvent<HTMLButtonElement>,
    tradeId: number | string
  ) => {
    setAnchorEl(event.currentTarget);
    let flag = false;
    getAllSubsMailListForProject("", tradeId, undefined)
      .then((resp) => {
        if (resp === null || resp.data === new Map()) {
          return;
        }
        const temp = resp.data.subMailsList.map((data: string)=>({
          mail:data,
          isSelected:true
        }));
        setSubMultiMails(temp);
        handleUpdateNewMails!(
          Array.from(new Set([...selectedMails, ...resp.data.subMailsList]))
        );
        if(temp.length > 0){
          flag = true
        }
        setIsSelectAll(flag);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const handleFetchAddedSubs = (_event: React.MouseEvent<HTMLButtonElement>) => {
    // setAnchorEl(event.currentTarget);
    let flag = false;
    getAddedSubsForProject("", projectId, undefined)
      .then((resp) => {
        if (resp === null || DataUtil.isObjectsAreEqual(resp.data, {})) {
          return;
        }
        const temp = resp.data.subMailsList.map((data: string) => ({
          mail: data,
          isSelected: true,
        }));
        if (resp.data.subMailsList.length > 0) {
          flag = true;
        }
        setSubMultiMails(temp);
        let updateSubMails = [...resp.data.subMailsList];
        subMultiMails.forEach((data) => {
          const isAlreadyPresent = updateSubMails.includes(data.mail);
          if (isAlreadyPresent && !data.isSelected) {
            updateSubMails = updateSubMails.filter(
              (updatedData) => updatedData !== data.mail
            );
          } else if (data.isSelected) {
            updateSubMails = Array.from(
              new Set([...updateSubMails, data.mail])
            );
          }
        });
        handleUpdateNewMails!(resp.data.subMailsList);
        setIsSelectAll(flag);
      })
      .catch(() => {})
      .finally(() => {});
  };

  const createPopOverRowsStructure = () => {
    return subMultiMails.map(
      (subMail: { mail: string; isSelected: boolean }, index) => ({
        subMail: subMail.mail,
        cell: (
          <Checkbox
            key={index}
            className="p-0"
            value={subMail}
            checked={subMail.isSelected}
            onChange={() => {
              const updatedList = subMultiMails.map((item, index) => {
                if (item.mail === subMail.mail) {
                  return { ...item, isSelected: !item.isSelected };
                }
                return item;
              });

              setSubMultiMails(updatedList);
              setIsSelectAll(updatedList.every((data) => data.isSelected));
            }}
          />
        ),
      })
    );
  };

  return (
    <FormControl className="col-12">
      <InputLabel id="select-label" size="small" className="m-3 bg-light">
        Send To :
      </InputLabel>
      <Select
        multiple={true}
        labelId="select-label"
        id="Status"
        name="Status"
        label=" Send To :"
        open={isExpanded}
        placeholder="Send To :"
        onOpen={(e) => {
          handleTradesChipClick(e);
          if (mainSelectField.includes("All Sub Contractor")) {
            handleAllSubChipClick(e);
          }
        }}
        onClose={(e) => {
          setIsExpanded(false);
        }}
        value={
          mainSelectField.includes("Trade")
            ? tradeSelectedFieldList
            : mainSelectField
        }
        className="bg-white m-3"
        size="small"
        MenuProps={MenuProps}
        renderValue={renderSelectValue}
      >
        {mainSelectField.includes("Trade")
          ? renderTradeSelect()
          : renderDefaultSelect()}
        <Popover
          id={open ? "subMails-popover" : undefined}
          open={open}
          anchorEl={anchorEl}
          anchorReference="anchorPosition"
          anchorPosition={{ top: 200, left: 400 }}
          onClose={() => {
            setAnchorEl(null);
            setIsExpanded(false);
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          slotProps={{
            paper: {
              style: {
                padding: "20px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              },
            },
          }}
          sx={{
            "& .MuiBackdrop-root": {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              pointerEvents: "none",
            },
          }}
        >
          <div className="col-12 d-flex justify-content-end">
            <FontAwesomeIcon
              className="pointer"
              icon={faClose}
              size="2x"
              onClick={() => {
                setAnchorEl(null);
                setIsExpanded(false);
              }}
            />
          </div>
          <div className="container mb-5">
            <CustomTable
              rows={createPopOverRowsStructure()}
              headCells={popOverHeader}
              maxHeight={200}
            />
          </div>
          <div className="row">
            <Button
              className="btn col-3  mx-auto"
              onClick={() => {
                let updateSubMails = [...selectedMails];
                subMultiMails.forEach((data) => {
                  const isAlreadyPresent = updateSubMails.includes(data.mail);
                  if (isAlreadyPresent && !data.isSelected) {
                    updateSubMails = updateSubMails.filter(
                      (updatedData) => updatedData !== data.mail
                    );
                  } else if (data.isSelected) {
                    updateSubMails = Array.from(
                      new Set([...updateSubMails, data.mail])
                    );
                  }
                });
                handleUpdateNewMails!(updateSubMails);
                setAnchorEl(null);
                setIsExpanded(false);
              }}
            >
              Save Changes
            </Button>
          </div>
        </Popover>
      </Select>
    </FormControl>
  );
};

export default InviteSubDropdownField;
