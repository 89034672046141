import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";

interface Props {
  id: string;
  name: string;
  value: string | number | null;
  defaultValue?: any;
  label: string;
  handleChange?: (_event: any) => void;
  error?: any;
  readonly?: boolean;
  disabled?: boolean;
  shrink?: boolean;
  required?: boolean;
  helperText?: string;
  handleBlur?: () => void;
  handleOnClick?: () => void;
}

const TimePickerField = ({
  name,
  label,
  value,
  required = false,
  helperText,
  error,
  readonly = false,
  handleChange,
}: Props) => {
  const onChange = (
    newValue: string | number | dayjs.Dayjs | Date | null | undefined
  ) => {
    const formattedDate = newValue ? dayjs(newValue).format("HH:mm") : "";
    handleChange!({ target: { name, value: formattedDate } });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
      <DemoContainer components={["TimePicker"]}>
        <TimePicker
          className="form-control"
          label={label}
          views={["hours", "minutes"]}
          value={value ? dayjs(value, "HH:mm", true) : null}
          slotProps={{
            openPickerButton: {
              color: "primary",
              edge: "end",
            },
            textField: {
              color: "secondary",
              size: "small",
              className: `bg-white form-control ${error ? "is-invalid" : ""}`,
              helperText: error ? "Invalid Time" : helperText,
              required: required ? required : false,
            },
          }}
          sx={{
            ".MuiPickersToolbar-root": {
              borderRadius: "2px",
              borderWidth: "1px",
              border: "1px solid",
            },
            ".MuiOutlinedInput-input": {
              background: readonly ? "#eee" : "white",
            },
          }}
          onChange={onChange}
          readOnly={readonly}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
};

export default TimePickerField;
