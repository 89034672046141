import { useEffect, useState } from "react";
import {
  InvitedByOtherHeaderCells,
  MyBidStatus,
  PostedByMeHeaderCells,
} from "../../../utils/const";
import { CustomTable } from "../../DataTable";
import {
  Button,
  FormControl,
  InputLabel,
  Menu,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  addInviteProjectToSelf,
  getInvitedProjects,
  onChangePostProjectStatus,
  projectSearchByStatus,
} from "../../../utils/api";
import Split from "react-split";
import InputField from "../../InputField";
import { PostProjectEnum, UserRoleEnum } from "../../../utils/enum";
import { SearchFilterInvitedType } from "../../../utils/types";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import ActionConfirmModal from "../../ActionConfirmModal/actionConfirmModal";
import { useDispatch } from "react-redux";
import { setProjectId } from "../../../redux/actions/projectFormActions";
import { useSelector } from "react-redux";
import { selectProjectId } from "../../../redux/selectors/selectors";
import { DataUtil } from "../../../utils/dataUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter, faFilterCircleXmark, faSearch } from "@fortawesome/free-solid-svg-icons";
import UsePermission from "../../../hooks/usePermission";

interface Props {
  onSelectProjectRow: (row: any) => void;
  bidId: number;
  onSelectCell: (cell: any) => void;
}

const MyBidsTable = ({ onSelectProjectRow, bidId, onSelectCell }: Props) => {
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showSnackBar, setShowsnackBar] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const dispatch = useDispatch();
  const projectId = useSelector(selectProjectId);
  const {isSubContractor} =UsePermission();
  

  const onSelectRow = (row: any, actionType: string, checked: boolean) => {
    if (actionType === "addToSelf") {
      console.log("row", row);
      dispatch(setProjectId(row.projectId));
      setProjectName(row.projectName);
      setOpenConfirmationModal(true);
    } else {
      onSelectProjectRow(row);
    }
  };
  const handleAddInviteProjectToSelf = () => {
    setIsLoading(true);
    addInviteProjectToSelf("", projectId, undefined)
      .then(async (response) => {
        setShowsnackBar(true);
        setMessage(response.message);
        setOpenConfirmationModal(false);
        setIsLoading(false);
        if (response.status === "OK") {
          setRefresh(!refresh);
        }
      })
      .catch((error: any) => {
        setShowsnackBar(true);
        setMessage(error.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="overflow-hidden mt-3">
        <div
          className="row"
          style={{
            height: "75vh",
          }}
        >
          {!isSubContractor ? (
            <Split
              sizes={[50, 50]}
              minSize={[100, 100]}
              expandToMin={false}
              gutterSize={8}
              gutterAlign="center"
              snapOffset={30}
              dragInterval={1}
              direction="horizontal"
              cursor="col-resize"
              className="d-flex h-100 overflow-hidden"
              gutter={() => {
                const gutterElement = document.createElement("div");
                gutterElement.className = "bg-secondary split-bar";
                return gutterElement;
              }}
            >
              <div className="overflowY-auto border">
                <div className="scrollable-pane">
                  <PostedByMeTable
                    onSelectRow={onSelectRow}
                    onSelectCell={onSelectCell}
                    refresh={refresh}
                  />
                </div>
              </div>
              <div className="overflowY-auto border">
                <div className="scrollable-pane">
                  <InvitedByOtherTable
                    onSelectRow={onSelectRow}
                    onSelectCell={onSelectCell}
                    bidId={bidId}
                  />
                </div>
              </div>
            </Split>
          ) : (
            <div className="overflowY-auto border">
              <div className="scrollable-pane">
                <InvitedByOtherTable
                  onSelectRow={onSelectRow}
                  onSelectCell={onSelectCell}
                  bidId={bidId}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ActionConfirmModal
        isOpen={openConfirmationModal}
        message="Are you sure you want to move this project to 'Posted By Me'?"
        title={`Move Project: <strong>${projectName}</strong>`}
        handleClose={() => setOpenConfirmationModal(false)}
        handleConfirm={handleAddInviteProjectToSelf}
        isLoading={isLoading}
      />
      <CustomizedSnackbar
        openSnackBar={showSnackBar}
        message={message}
        handleCloseSnackBar={() => setShowsnackBar(false)}
      />
    </>
  );
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface MyBidsProps {
  onSelectRow: (row: any, actionType: string, checked: boolean) => void;
  onSelectCell: (cell: any) => void;
}
interface PropsPosted extends MyBidsProps {
  refresh: boolean;
}

const PostedByMeTable = (props: PropsPosted) => {
  const [postRowData, setPostRowData] = useState<any>([]);
  const [postMultiStatus, setPostMultiStatus] = useState<string[]>([
    PostProjectEnum.ACTIVELY_BIDDING,
    PostProjectEnum.DRAFT,
  ]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleGetPostProjectList([
      PostProjectEnum.ACTIVELY_BIDDING,
      PostProjectEnum.DRAFT,
    ]);
  }, [props.refresh]);

  const handleGetPostProjectList = (multiStatus: string[]) => {
    const body = {
      postStatus: multiStatus,
    };
    setIsLoading(true);
    projectSearchByStatus("", undefined, body)
      .then((response: any) => {
        console.log(response.data);
        if (response.data === null) {
          setPostRowData([]);
        } else {
          setPostRowData(response.data);
        }
        setIsLoading(false)
      })
      .catch((error: any) => {
        console.log(error);
        setPostRowData([]);
        setIsLoading(false)
      });
  };

  const handleChange = (event: SelectChangeEvent<typeof postMultiStatus>) => {
    console.log("event", event);
    const { value } = event.target;
    setPostMultiStatus(typeof value === "string" ? value.split(",") : value);
    handleGetPostProjectList(
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handlePostStatusChange = (projectId: number, status: string) => {
    onChangePostProjectStatus("", projectId, { status })
      .then((response) => {
        if (response.status === "OK") {
          handleGetPostProjectList(postMultiStatus);
        }
      })
      .catch((error: any) => {
        console.log("error", error);
      });
  };

  return (
    <>
      <div className="d-flex align-items-center mt-3 mb-3">
        <div className="col-6 text-truncate size-lg p-1 ms-1">My Bids</div>
        <FormControl className="col-5 d-flex justify-content-end">
          <InputLabel
            id="select-label"
            size="small"
            className="py-1 bg-white size-sm"
          >
            Select Status
          </InputLabel>
          <Select
            multiple
            labelId="select-label"
            id="Status"
            name="Status"
            label="Select Status"
            value={postMultiStatus}
            className="bg-white w-auto"
            size="small"
            MenuProps={MenuProps}
            onChange={handleChange}
            input={<OutlinedInput label="Status" />}
          >
            {MyBidStatus.map((status: any, index: number) => (
              <MenuItem className="d-block ms-3" key={index} value={status}>
                {status}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div className="px-1">
        <CustomTable
          headCells={PostedByMeHeaderCells}
          rows={postRowData}
          onSelectRowClick={props.onSelectRow}
          onClickGetCell={props.onSelectCell}
          handleChangeDropDownStatus={handlePostStatusChange}
          isLoading={isLoading}
          maxHeight={380}
        />
      </div>
    </>
  );
};

interface PropsInvited extends MyBidsProps {
  bidId: number;
}

const DefaultSearchFiltered = {
  projectName: "",
  status: "",
  selectCity: "",
  orgName: "",
}

const InvitedByOtherTable = (props: PropsInvited) => {
  const [inviteRowData, setInviteRowData] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState<SearchFilterInvitedType>(DefaultSearchFiltered);
  const [screenDimensions, setScreenDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    const handleResize = () => {
      setScreenDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    console.log("render");
    setIsLoading(true);
    fetchGetInviteProjectBidsApi(undefined);
  }, []);

  const fetchGetInviteProjectBidsApi = (
    body:
      | {
          projectName: string;
          status: string;
          city: string;
          orgName: string;
        }
      | undefined
  ) => {
    getInvitedProjects("", undefined, body)
      .then((response: any) => {
        console.log(response.data);
        setIsLoading(true);
        if (response.data === null || response.data === undefined) {
          setInviteRowData([]);
        } else {
          setInviteRowData(response.data);
        }
        setIsLoading(false);
      })
      .catch((error: any) => {
        console.log(error);
        setInviteRowData([]);
        setIsLoading(false);
      });
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //To be used afterwards
  // const handleChangeInvitedStatus = (projectId: number, newStatus: string) => {
  //   console.log(newStatus);
  //   const status = newStatus.replace(/^"|"$/g, "");
  //   console.log(status);
  //   updateStatusForAllBids("", projectId, status)
  //     .then((response) => {
  //       if (response.status === "OK") {
  //         setRefresh(true);
  // setMessage(response.message);
  // setShowsnackBar(true);
  //       }
  //     })
  //     .catch((error: any) => {
  //       console.log("error :>> ", error);
  //     });
  // };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    console.log("name", name);
    console.log("value", value);
    setSearch({
      ...search,
      [name]: value,
    });
  };
  const handleSearchByFilterInInvited = (flag: boolean) => {
    const { projectName, status, selectCity, orgName } = search;
    console.log("value for filter=> ", search);
    const body = flag
      ? { projectName: "", status: "", city: "", orgName: "" }
      : {
          projectName: projectName,
          status: status,
          city: selectCity,
          orgName: orgName.trim(),
        };
    fetchGetInviteProjectBidsApi(body);
    handleClose();
  };
  return (
    <>
      <div className="container">
        <div className="row gx-0 py-3">
          <div className="col-lg-2 col-md-7 col-sm-7 size-lg text-truncate mt-2">
            Invited
          </div>
          {screenDimensions.width > 1000 ? (
            <div className="col-lg-10 col-md-4 col-sm-4 d-flex gap-1">
              <FormControl className="col-lg-3 col-md-6 col-sm-6">
                <InputField
                  id="projectName"
                  name="projectName"
                  type="text"
                  className="form-control"
                  label="Project Name"
                  value={search.projectName}
                  handleChange={handleChange}
                />
              </FormControl>
              <FormControl className="col-lg-2 col-md-6 col-sm-6">
                <InputField
                  id="selectCity"
                  name="selectCity"
                  type="text"
                  className="form-control"
                  label="City"
                  value={search.selectCity}
                  handleChange={handleChange}
                />
              </FormControl>
              <FormControl className="col-lg-3 col-md-6 col-sm-6">
                <InputField
                  id="orgName"
                  name="orgName"
                  type="text"
                  className="form-control"
                  label="Organization Name"
                  value={search.orgName}
                  handleChange={handleChange}
                />
              </FormControl>
              <div className="class-lg-2 mt-1 gap-1">
                <Button
                  className="btn px-0 ms-1"
                  onClick={() => {
                    setSearch({
                      projectName: "",
                      status: "",
                      selectCity: "",
                      orgName: "",
                    });
                    handleSearchByFilterInInvited(true);
                  }}
                  title="Clear"
                >
                  <FontAwesomeIcon icon={faFilterCircleXmark} size="lg" />
                </Button>
                <Button
                  className="btn px-0 ms-1"
                  onClick={() => handleSearchByFilterInInvited(false)}
                  disabled={DataUtil.isObjectsAreEqual(
                    search,
                    DefaultSearchFiltered
                  )}
                  title="Search"
                >
                  <FontAwesomeIcon icon={faSearch} size="lg" />
                </Button>
              </div>
            </div>
          ) : (
            <div className="col-4 d-flex justify-content-start p-1">
              <Button className="btn" onClick={handleClick}>
              <FontAwesomeIcon icon={faFilter} size="lg" />
              </Button>
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="px-1">
          <CustomTable
            headCells={InvitedByOtherHeaderCells}
            rows={inviteRowData}
            onSelectRowClick={props.onSelectRow}
            onClickGetCell={props.onSelectCell}
            isLoading={isLoading}
            maxHeight={380}
          />
        </div>
      </div>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <div className="container">
          <div className="row justify-content-center align-items-center mt-3">
            <InputField
              id="projectName"
              name="projectName"
              type="text"
              className="form-control"
              label="Project Name"
              value={search.projectName}
              handleChange={handleChange}
            />
          </div>
          <div className="row justify-content-center align-items-center mt-3">
            <InputField
              id="selectCity"
              name="selectCity"
              type="text"
              className="form-control"
              label="City"
              value={search.selectCity}
              handleChange={handleChange}
            />
          </div>
          <div className="row justify-content-center align-items-center mt-3">
            <InputField
              id="orgName"
              name="orgName"
              type="text"
              className="form-control"
              label="Organization Name"
              value={search.orgName}
              handleChange={handleChange}
            />
          </div>
          <div className="row justify-content-center align-items-center gap-3 mt-3">
            <Button
              className="btn col-3"
              onClick={() => {
                setSearch({
                  projectName: "",
                  status: "",
                  selectCity: "",
                  orgName: "",
                });
                handleSearchByFilterInInvited(true);
              }}
            >
              Clear
            </Button>
            <Button
              className="btn col-3"
              onClick={() => handleSearchByFilterInInvited(false)}
              disabled={DataUtil.isObjectsAreEqual(
                search,
                DefaultSearchFiltered
              )}
            >
              Search
            </Button>
          </div>
        </div>
      </Menu>
    </>
  );
};

export default MyBidsTable;
