import {useEffect, useState } from "react";
import { RichTreeView } from "@mui/x-tree-view/RichTreeView";
import React from "react";
import {
  TableContainer,
  Table,
  TableRow,
  TableBody,
  styled,
  Button,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { TreeItem2, TreeItem2Label } from "@mui/x-tree-view/TreeItem2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong, faRightLong } from "@fortawesome/free-solid-svg-icons";
import { getExpertiseArea, getProjectTrads, saveProjectTrades } from "../../utils/api";
import { useTreeItem2 } from "@mui/x-tree-view/useTreeItem2/useTreeItem2";
import { useLocation } from "react-router-dom";
import { FormControl, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import Loader from "../Loader";
import { setSelectedTradesDetails } from "../../redux/actions/projectFormActions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectIsPostProjectButtonVisible, selectProjectId, selectThemeColor } from "../../redux/selectors/selectors";
import CustomizedSnackbar from "../CustomizedSnackbar";
import UseTreeSelectionFunction from "../../hooks/useTreeSelectionFunction";
import UseTreeUnselectionFunction from "../../hooks/useTreeUnselectionFunction";
import UseCommonTreeFunction from "../../hooks/useCommonTreeFunction";
import { TreeNodeType } from "../../utils/types";

const SelectDivisionSpecialization = ({formikProps = {}} : any) => {

 
  const [isLoading, setIsLoading] = useState(false);
  const [nodes, setNodes] = useState<TreeNodeType[]>([]);
  const [initialNodes, setinitialNodes] = useState<TreeNodeType[]>([]);
  const [expandedItems, setExpandedItems] = useState<string[]>([]);
  const [expandedSelectedItems, setExpandedSelectedItems] = useState<
    string[]
  >([]);
  const [selectedNodes, setSelectedNodes] = useState<any>([]);
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [selectedSpecifications, setSelectedSpecifications] = useState<any>([]);
  const [showSnackBar, setShowsnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [selectedResult, setSelectedResult] = useState<any>([]);
  const [searchItem, setSearchItem] = useState("");

  const {
    selectedDivAndSpecs,
    handleStateDivAndSpecs,
    showTradeLable,
    values,
    setFieldValue,
    errors,
  } = formikProps;
  const location = useLocation();
  const dispatch = useDispatch();

  const isLogin = location.pathname !== "/sign-up";
  const color = useSelector(selectThemeColor);
  const projectId = useSelector(selectProjectId);
  const toShowButton = useSelector(selectIsPostProjectButtonVisible);

  const setResultValue = () => {
    if (isLogin) {
      if (selectedDivAndSpecs) {
        return selectedDivAndSpecs;
      }
      else {
        return [];
      }
    } else if (values) {
      return values.tradeList;
    }
  }

  const [result, setResult] = useState<any[]>(setResultValue());

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!isLogin) {
      setIsLoading(true);
      getExpertiseArea("", undefined, undefined)
        .then((resp) => {
          const data = structuredData(resp.data.ListForTree);
          setNodes(data);
          setinitialNodes(data);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else if (isLogin) {
      setIsLoading(true);
      getProjectTrads("", projectId, undefined)
        .then((resp) => {
          console.log("resp", resp);
          if (resp === null || resp.data === new Map()) {
            return;
          }
          const tradeData = structuredData(resp.data.tradeList);
          setNodes(tradeData);
          setinitialNodes(tradeData);
          const selectedNestedTradeData = structuredData(
            resp.data.selectedNestedTradeList
          );
          setResult(selectedNestedTradeData);
          const selectedTradeData = structuredData(resp.data.selectedTradeList);
          setSelectedResult(selectedTradeData);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
}, []);

/* eslint-disable react-hooks/exhaustive-deps */
useEffect(() => {
  // const itemIds: any[] = [];
  // const registerItemId = (item: any) => {
  //   if (item.children?.length) {
  //     itemIds.push(item.id);
  //     item.children.forEach(registerItemId);
  //   }
  // };
  // result.forEach(registerItemId);
  // setExpandedSelectedItems(itemIds);
  if (isLogin && handleStateDivAndSpecs) {
    handleStateDivAndSpecs(result, null);
  } else if (!isLogin) {
    setFieldValue("tradeList", result);
  }
}, [result]);

  const createChildTradesStructure = (list: any, tempList : any) => {
    let tempTradeMap : any = tempList;
    list.forEach((value : any) => {
      tempTradeMap.push(value.id)
      const { children } = value;
      if (children && children.length > 0) {
        createChildTradesStructure(children, tempTradeMap);
      }
    });
    return  tempTradeMap;
  };

  const handleSaveTrades = () => {
    let tempTradeList: any = [];
    let tradeResponseList: any = [];
    result.forEach((value: any) => {
      const { id, children } = value;
      if (children && children.length > 0) {
        tempTradeList = [
          ...tradeResponseList,
          ...createChildTradesStructure(children, [id]),
        ];
      } else {
        tempTradeList = [...tradeResponseList, id];
      }
      tradeResponseList = [...tempTradeList];
    });
    let tradeIdsList = selectedResult.map((result: any) => result.id);

    saveProjectTrades("", projectId, { tradeIds: tradeIdsList })
      .then((response: any) => {
        if (response.status === "OK") {
          setMessage("Trades Saved Successfully");
          setShowsnackBar(true);
        } else {
          setMessage("Failed!");
          setShowsnackBar(true);
        }
      })
      .catch((error: any) => {});
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.root}`]: {
      fontSize: 12,
      fontWeight: "600",
      padding: "0.1 rem !important",
      width: "auto",
    },
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: color.lightColor,
      fontSize: 12,
      fontWeight: "600",
      padding: "0.1 rem",
      width: "auto",
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 12,
      padding: "0.1 rem",
    },
  }));

  const getTradeAndSubsCountCol = () => {
    if (isLogin) {
      return (
        <div className="row border-bottom border-top position-sticky gx-0" style={{position:"fixed"}}>
          <span className="col-9 border-end ms-3">Trades</span>
          <span className="col-2"># Subs</span>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const {getNthKeyNodesResp,mergeSelectedNodesResp, sortChildrenItemsById} =  UseCommonTreeFunction();
  const { getNewResultResp } = UseTreeSelectionFunction();
  const {getUnselctNewResultResp} =  UseTreeUnselectionFunction();

  const handleExpandedItemsChange = (
    _event: React.SyntheticEvent,
    itemIds: string[]
  ) => {
    setExpandedItems(itemIds);
  };

  const handleExpandedSelectedItemsChange = (
    _event: React.SyntheticEvent,
    itemIds: string[]
  ) => {
    setExpandedSelectedItems(itemIds);
  };

  const structuredData = (data: any) => {
    return (
      data &&
      data.map((item: any) => ({
        id: item.id,
        label: item.name,
        tradeCode: item.tradeCode,
        subCount: item.subCount,
        bidRaised:item.bidRaised,
        children: item.subDivisionList
          ? getChildrenList(item.subDivisionList)
          : [],
      }))
    );
  };

  const getChildrenList: any = (items: any[]) => {
    return items.map((item) => ({
      id: item.id,
      label: item.name,
      tradeCode: item.tradeCode,
      subCount: item.subCount,
      bidRaised: item.bidRaised,
      children: item.subDivisionList
        ? getChildrenList(item.subDivisionList)
        : [],
    }));
  };

  const onSelectingNode = (key: any, unUsedNodes: any) => {
    let tempNodes = [...initialNodes];
    let selectedNode = getNthKeyNodesResp(key, tempNodes);
    if (selectedNode) {
      try {
        selectedNode = { ...selectedNode };
        setResult((prev) => {
          const mergedNodes = mergeSelectedNodesResp(key, selectedNode, prev);
          const mergeSortedNodes = [...mergedNodes].sort((a, b) => a.id - b.id);
          console.log("Updated result", mergeSortedNodes);
          console.log("Previous Result", prev);
          return mergeSortedNodes;
        });
      } catch (error) {
        console.error("Error during merging nodes:", error);
      }
    } else {
      console.error("selected nodes is: ", selectedNode);
      return;
    }
  };

  const toRemoveSelectedNode = (key: any, tempNodes: any) => {
    const newNodes = getNewResultResp(key, tempNodes);
    if (!newNodes || newNodes.length === 0) {
      console.warn("No nodes found for the given key:", key);
      setNodes([]);
      return;
    }
    const sortedNodes = [...newNodes].sort((a, b) => a.id - b.id);
    console.log("Sorted nodes after removal:", sortedNodes);
    setNodes(sortedNodes);
  };

  const onUnSelectingNode = (key: any, result: any) => {
    try {
      const newResult = getUnselctNewResultResp(key, result);
      if (!Array.isArray(newResult)) {
        console.log("Expected an array from getUnselctNewResultResp");
        return;
      }
      setResult(newResult.sort((a, b) => a.id - b.id));
    } catch (error) {
      console.error("Error in onUnSelectingNode:", error);
    }
  };

  const toAddUnSelecteToNodes = (key: any) => {
    try {
      let selectedNode = getNthKeyNodesResp(key, result);
      if (nodes.length === 0) {
        setNodes(sortChildrenItemsById([selectedNode]));
      } else {
        selectedNode = mergeSelectedNodesResp(key, selectedNode, nodes);
        const sortedNodes = sortChildrenItemsById(
          [...selectedNode].sort((a, b) => a.id - b.id)
        );
        console.log("sorted nodes ====> ", sortedNodes);
        setNodes(sortedNodes);
      }
    } catch (error) {
      console.error("Error in toAddUnSelecteToNodes:", error);
    }
  };

  const resetToInitialdNodes = (initialNodeList: any) => {
    console.log('initialNodeList', initialNodeList)
    // add result.length === 0 instead of true
    if (true) {
      setNodes(() => initialNodeList);
      return;
    }
    result.forEach((resultNode) => {
      console.log("Processing node:", resultNode);
      const tempNode = getNthKeyNodesResp(resultNode.id, initialNodeList);
      if (!tempNode) {
        return;
      }
      if (resultNode.id === tempNode.id) {
        if (
          (Array.isArray(tempNode.children) &&
            Array.isArray(resultNode.children) &&
            tempNode.children.length === resultNode.children.length) ||
          resultNode.children.length === 0
        ) {
          toRemoveSelectedNode(resultNode.id, initialNodeList);
        } else if (resultNode.children && resultNode.children.length > 0) {
          resetChildInitialdNodes(resultNode.children, initialNodeList);
        }
      }
    });
  };

  const resetChildInitialdNodes = (
    childNodeList: any,
    initialNodeList: any
  ) => {
    console.log("Processing initialNodeList", initialNodeList);
    childNodeList.forEach(
      (childNode: { id: number; children: string | any[] }) => {
        console.log("Processing child node:", childNode);
        const tempNode = getNthKeyNodesResp(childNode.id, initialNodeList);
        console.log("tempNode.children", tempNode);
        if (!tempNode) {
          return;
        }
        if (
          (tempNode.children === null &&
            tempNode.children.length === childNode.children.length) ||
          childNode.children.length === 0
        ) {
          toRemoveSelectedNode(childNode.id, initialNodeList);
        } else if (childNode.children && childNode.children.length > 0) {
          resetChildInitialdNodes(childNode.children, initialNodeList);
        }
      }
    );
  };

  const removeSpaces = (str: string) => str.replace(/\s+/g, "");

  const handleKeyDown = (event: any) => {
    const { key, value } = event;
    console.log('key', key)
    if (key === "Backspace" || /^[a-zA-Z0-9 .,]$/.test(key)) {
      const tempInitialNode = [...initialNodes];
      searchToFilteredNodes(tempInitialNode, value);
      return;
    }
    event.preventDefault();
  };

  const searchToFilteredNodes = (searchNodes: any, searchTerm: string) => {
    console.log('!searchTerm ', !!searchTerm )
    if (!searchTerm || searchTerm === "") {
      resetToInitialdNodes(searchNodes);
    } else {
      const isNumeric = /^[0-9\s]*$/.test(searchTerm);
      const cleanedSearchTerm = isNumeric
        ? removeSpaces(searchTerm).toLowerCase()
        : searchTerm.toLowerCase();

      const filterNodes = (nodes: TreeNodeType[]): TreeNodeType[] => {
        return nodes.reduce<TreeNodeType[]>((acc, node) => {
          const itemNameLower = node.label.toLowerCase();
          const cleanedTradeCode = node.tradeCode
            .replace(/\s+/g, "")
            .toLowerCase();

          const matches =
            itemNameLower.includes(cleanedSearchTerm) ||
            cleanedTradeCode.includes(cleanedSearchTerm);

          const filteredChildren =
          Array.isArray(node.children) && node.children.length > 0 ? filterNodes(node.children) : [];

          if (matches || filteredChildren.length > 0) {
            acc.push({ ...node, children: filteredChildren });
          }
          return acc;
        }, []);
      };
      setNodes(filterNodes);
    }
  };

  function CustomLabel(props: any) {
    const { tooltip, labelProps, ...other } = props;
    // const filteredTempNodes = (items: any, idToRemove: any) => {
    //   try {
    //     return items.filter((item: any) => {
    //       if (item.id === idToRemove) {
    //         return false;
    //       } else if (item.children && item.children.length > 0) {
    //         item.children = filteredTempNodes(item.children, idToRemove);
    //         return true;
    //       } else {
    //         return true;
    //       }
    //     });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };

    const filteredSelectedResult = (
      items: TreeNodeType[],
      selectedKey: any
    ): TreeNodeType | TreeNodeType[] | null => {
      if (!items) {
        return null;
      }

      for (const item of items) {
        if (item.id === selectedKey) {
          return item;
        }

        if (item.children) {
          const result = filteredSelectedResult(item.children, selectedKey);
          if (result) {
            return result;
          }
        }
      }

      return null;
    };

    const getOnlySelectedResult = (key: any): TreeNodeType | TreeNodeType[]=> {

      let returnList : TreeNodeType | TreeNodeType[] | null = [];
      try {
        let list: any = getNthKeyNodesResp(key, nodes);
        if (list && list.id === key) {
          returnList = list;
        } else {
          returnList = filteredSelectedResult(list.children, key);
        }
      } catch (error) {
        console.error(error);
        returnList = [];
      }
      return returnList ? returnList : [];
    };

    return (
      <>
        <TableContainer>
          <Table aria-label="customized table">
            <TableBody>
              <TableRow key="trades">
                <StyledTableCell style={{ width: 1000, padding: "0" }}>
                  <div
                    className="d-block align-items-center p-1"
                    style={{ flex: "60" }}
                    onClick={() => {
                      setSelectedSpecifications([]);
                      if (!selectedNodes.includes(labelProps.itemId)) {
                        setSelectedNodes([...selectedNodes, labelProps.itemId]);
                      } else {
                        const updatedList = selectedNodes.filter(
                          (id: any) => id !== labelProps.itemId
                        );
                        setSelectedNodes(updatedList);
                      }
                    }}
                  >
                    <span className="text-primary text-wrap text-break size-sm">
                      {labelProps.tradeCode}
                    </span>
                    <TreeItem2Label
                      {...other}
                      style={
                        {
                          // paddingRight: "5px",
                          // paddingLeft: "5px",
                        }
                      }
                    />
                  </div>
                </StyledTableCell>
                <StyledTableCell style={{ width: 100, padding: "0" }}>
                  <div className="d-flex">
                    <span
                      className="size-sm fw-blod"
                      style={{ color: color.darkColor }}
                    >
                      {labelProps.subCount}
                    </span>
                    {selectedNodes.includes(labelProps.itemId) && (
                      <div className="d-flex ms-1">
                        <FontAwesomeIcon
                          className="size-lg p-1"
                          icon={faRightLong}
                          style={{ color: color.darkColor }}
                          id={labelProps.itemId}
                          onClick={() => {
                            let tempSendingNodes: any = [...initialNodes];
                            let tempSelectedResult: any = [...selectedResult];
                            selectedNodes.forEach((key: any) => {
                              const nodeExists = selectedResult.some(
                                (node: { id: any }) => node.id === key
                              );
                              if (isLogin) {
                                if (nodeExists) {
                                  setShowsnackBar(true);
                                  setMessage("Trade already been selected");
                                } else {
                                  tempSelectedResult.push(
                                    getOnlySelectedResult(key)
                                  );
                                }
                              } else {
                                onSelectingNode(key, tempSendingNodes);
                              }
                              // toRemoveSelectedNode(key, tempSendingNodes);
                              // tempSendingNodes = filteredTempNodes(
                              //   tempSendingNodes,
                              //   key
                              // );
                            });
                            setSelectedNodes([]);
                            setSelectedResult(tempSelectedResult);
                            setIsLoading(false);
                            dispatch(setSelectedTradesDetails(result));
                          }}
                          size="1x"
                        />
                      </div>
                    )}
                  </div>
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }
  
  function CustomLabel2(props: any) {
    const { tooltip, labelProps, ...other } = props;
    return (
      <TableContainer>
        <Table aria-label="customized table">
          <TableBody>
            <TableRow key="trades">
              <StyledTableCell style={{ width: 1000, padding: "0" }}>
                <div className="d-flex">
                  {selectedSpecifications.includes(labelProps.itemId) && (
                    <div className="d-flex align-items-center">
                      <FontAwesomeIcon
                        className="p-1"
                        style={{ color: color.mediumColor }}
                        icon={faLeftLong}
                        id={labelProps.itemId}
                        onClick={() => {
                          onUnSelectingNode(labelProps.itemId, result);
                          toAddUnSelecteToNodes(labelProps.itemId);
                        }}
                        size="lg"
                      />
                    </div>
                  )}
                  <div
                    className="d-block  p-1 ms-2"
                    style={{ flex: "60" }}
                    onClick={() => {
                      setSelectedNodes([]);
                      if (!selectedSpecifications.includes(labelProps.itemId)) {
                        setSelectedSpecifications([labelProps.itemId]);
                      } else {
                        const updatedList = selectedSpecifications.filter(
                          (node: { id: any }) => node.id !== labelProps.itemId
                        );
                        setSelectedSpecifications(updatedList);
                      }
                    }}
                  >
                    <span className="me-2 text-primary text-wrap text-break size-sm">
                      {labelProps.tradeCode}
                    </span>
                    <TreeItem2Label
                      {...other}
                      style={
                        {
                          paddingRight: "5px",
                          paddingLeft: "5px",
                        }
                      }
                    />
                  </div>
                </div>
              </StyledTableCell>
              <StyledTableCell style={{ width: 100 }}>
                <div className="d-flex">
                  {/* <span className="me-2 text-primary text-wrap text-break size-sm">
                    {labelProps.tradeCode}
                  </span> */}
                  <span
                    className="ms-1 size-sm"
                    style={{ color: color.darkColor }}
                  >
                    {labelProps.subCount}
                  </span>
                </div>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }


  const CustomTreeItem = React.forwardRef(
    (props: any, ref: React.Ref<HTMLLIElement>) => {
      const { labelTooltip, itemId, ...other } = props;
      const { publicAPI } = useTreeItem2({
        itemId,
        rootRef: ref,
      });
      if (!itemId) {
        return null;
      }
      const itemData = (publicAPI as any).getItem(itemId);
      return (
        <>
          <TreeItem2
            {...props}
            ref={ref}
            slots={{ label: CustomLabel }}
            slotProps={{
              label: {
                labelProps: {
                  ...other,
                  itemId,
                  parenttrade: itemData.parenttrade,
                  tradeCode: itemData.tradeCode,
                  subCount: itemData.subCount,
                },
              },
            }}
            sx={{
              "& .MuiTreeItem-label": {
                  fontSize: "14px" 
               }
             }}
          />
        </>
      );
    }
  );

  const CustomTreeItem2 = React.forwardRef(
    (props: any, ref: React.Ref<HTMLLIElement>) => {
      const { labelTooltip, itemId, ...other } = props;
      const { publicAPI } = useTreeItem2({
        itemId,
        rootRef: ref,
      });
      const itemData = (publicAPI as any).getItem(itemId);
   
      return (
        <TreeItem2
          {...props}
          ref={ref}
          slots={{ label: CustomLabel2 }}
          color="primary"
          slotProps={{
            label: {
              labelProps: {
                ...other,
                itemId: itemId,
                parenttrade: itemData.parenttrade,
                tradeCode: itemData.tradeCode,
                subCount: itemData.subCount,
              },
            },
          }}
          sx={{
            "& .MuiTreeItem-label": {
                fontSize: "14px" 
             }
           }}
        />
      );
    }
  );

  return (
    <>
      <div className="container d-flex justify-content-center">
        <div className="row g-0 w-100 justify-content-center">
          <div className="col-6">
            {!showTradeLable && (
              <div className="d-flex mb-2">
                <h4 className="opacity-70 p-0 fs-4 ms-1 header-font">Trades</h4>
              </div>
            )}
            <div
              className="card box"
              style={{
                height: "51vh",
                maxWidth: " 525px",
                overflowY: "scroll",
              }}
            >
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mt-2 gx-0">
                <FormControl className="search-box">
                  <TextField
                    className="bg-white col-7 col-sm-7 col-md-7 col-lg-7"
                    size="small"
                    variant="outlined"
                    value={searchItem}
                    onChange={(
                      event: React.ChangeEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >
                    ) => {
                      setShowClearIcon(
                        event.target.value === "" ? "none" : "flex"
                      );
                      setSearchItem((prev) => event.target.value);
                      const tempInitialNode = [...initialNodes];
                      searchToFilteredNodes(tempInitialNode, event.target.value);
                    }}
                    onKeyDown={handleKeyDown}
                    placeholder="Search Trade"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment
                          position="end"
                          style={{ display: showClearIcon }}
                          onClick={() => {
                            const tempInitialNode = [...initialNodes];
                            resetToInitialdNodes(tempInitialNode);
                            setSearchItem("");
                            setShowClearIcon("none");
                          }}
                        >
                          <ClearIcon className="pointer" />
                        </InputAdornment>
                      ),
                      sx: { width: "20.5vw" },
                    }}
                  />
                </FormControl>
              </div>
              {nodes.length > 0 ? (
                <>
                  {getTradeAndSubsCountCol()}
                  <RichTreeView
                    className="w-100 d-inline"
                    aria-label="customized"
                    expandedItems={expandedItems}
                    onExpandedItemsChange={handleExpandedItemsChange}
                    sx={{
                      overflowX: "hidden",
                      minHeight: 270,
                      flexGrow: 1,
                      maxWidth: 500,
                    }}
                    items={nodes}
                    slots={{
                      item: CustomTreeItem,
                    }}
                    expansionTrigger="iconContainer"
                  />
                </>
              ) : !isLoading ? (
                <div
                  className="container"
                  style={{
                    height: "51vh",
                    maxWidth: "525px",
                    borderRadius: "8px",
                    padding: "16px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <p className="text-muted text-center">
                    { (searchItem === "" || !searchItem) && result.length > 0 && nodes.length === 0
                      ? "You have selected all available trades."
                      : "No trades were found. Please contact the system administrator for assistance."}
                  </p>
                </div>
              ) : (
                <Loader
                  className="d-flex justify-content-center mt-5 py-5"
                  label="Fetching Trades"
                />
              )}
            </div>
          </div>
          <div className="col-6">
            {!showTradeLable && (
              <div className="d-flex">
                <h4 className="opacity-70 p-0 mt-2  d-flex header-font">
                  Selected Trades
                </h4>
              </div>
            )}
            {selectedResult ? (
              <div
                className="card box"
                style={{
                  height: "51vh",
                  maxWidth: "525px",
                }}
              >
                {getTradeAndSubsCountCol()}
                {isLogin ? (
                  <div style={{ overflowY: "scroll", overflowX: "hidden" }}>
                    {selectedResult &&
                      selectedResult.length > 0 &&
                      selectedResult.map((result: TreeNodeType) => (
                        <TableContainer>
                          <Table aria-label="customized table">
                            <TableBody>
                              <TableRow key="trades">
                                <StyledTableCell
                                  style={{ width: 1000, padding: "0" }}
                                >
                                  <div className="d-flex">
                                    {selectedSpecifications.length > 0 &&
                                      selectedSpecifications.includes(
                                        result.id
                                      ) &&
                                      !result.bidRaised && (
                                        <div className="d-flex align-items-center">
                                          <FontAwesomeIcon
                                            className="p-1"
                                            style={{ color: color.mediumColor }}
                                            icon={faLeftLong}
                                            id={result.id as any}
                                            onClick={() => {
                                              if (!result.bidRaised) {
                                                onUnSelectingNode(
                                                  result.id,
                                                  selectedResult
                                                );
                                                toAddUnSelecteToNodes(
                                                  result.id
                                                );
                                                let filteredselectedResult = [
                                                  ...selectedResult,
                                                ].filter(
                                                  (data: any) =>
                                                    data.id !==
                                                    selectedSpecifications[0]
                                                );
                                                setSelectedResult(
                                                  filteredselectedResult
                                                );
                                              }
                                            }}
                                            size="lg"
                                          />
                                        </div>
                                      )}
                                    <div
                                      className="d-block  p-1 ms-2 pointer"
                                      style={{
                                        flex: "60",
                                        opacity: result.bidRaised ? "0.5" : "1",
                                      }}
                                      onClick={() => {
                                        if (!result.bidRaised) {
                                          if (
                                            !selectedSpecifications.includes(
                                              result.id
                                            )
                                          ) {
                                            setSelectedSpecifications([
                                              result.id,
                                            ]);
                                          } else {
                                            const updatedList =
                                              selectedSpecifications.filter(
                                                (node: any) =>
                                                  node !== result.id
                                              );
                                            setSelectedSpecifications(
                                              updatedList
                                            );
                                          }
                                        }
                                        // setSelectedMultiNodes([...selectedMultiNodes, labelProps.itemId]);
                                      }}
                                    >
                                      <span className="me-2 text-primary text-wrap text-break size-sm">
                                        {result ? result.tradeCode : <></>}
                                      </span>
                                      <span>
                                        {result ? result.label : <></>}
                                      </span>
                                    </div>
                                  </div>
                                </StyledTableCell>
                                <StyledTableCell style={{ width: 100 }}>
                                  <div className="d-flex">
                                    <span
                                      className="ms-1 size-sm"
                                      style={{ color: color.darkColor }}
                                    >
                                      {result ? result.subCount : <></>}
                                    </span>
                                  </div>
                                </StyledTableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      ))}
                  </div>
                ) : result.length > 0 ? (
                  <RichTreeView
                    className="w-100 d-inline"
                    aria-label="customized"
                    expandedItems={expandedSelectedItems}
                    onExpandedItemsChange={handleExpandedSelectedItemsChange}
                    sx={{
                      overflowX: "hidden",
                      minHeight: 270,
                      flexGrow: 1,
                      maxWidth: 500,
                    }}
                    items={result}
                    slots={{
                      item: CustomTreeItem2,
                    }}
                    expansionTrigger="iconContainer"
                  />
                ) : (
                  <div
                    className="container"
                    style={{
                      height: "51vh",
                      maxWidth: "525px",
                      borderRadius: "8px",
                      padding: "16px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <p className="text-muted fs-6">
                      No trades selected yet. Please select some trades to see
                      the details.
                    </p>
                  </div>
                )}
              </div>
            ) : (
              <div
                className="card box"
                style={{
                  height: "51vh",
                  maxWidth: " 525px",
                  overflowY: "scroll",
                }}
              >
                {!isLogin ? (
                  <p className="p-2 text-center text-danger">
                    {errors.tradeList}
                  </p>
                ) : (
                  <p className="p-2 text-center text-danger">
                    {"No Trades or Specifications Selected"}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {isLogin && toShowButton && (
        <div className="col-11 d-flex justify-content-center">
          <Button
            className="btn col-2 p-1"
            style={{
              background: color.darkColor,
              color: "white",
              position: "fixed",
            }}
            onClick={handleSaveTrades}
          >
            Save Trades
          </Button>
        </div>
      )}
      <CustomizedSnackbar
        openSnackBar={showSnackBar}
        message={message}
        handleCloseSnackBar={() => setShowsnackBar(false)}
      />
    </>
  );
};

export default SelectDivisionSpecialization;