import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";
import LoaderButton from "../Loader/loaderButton";

interface Props {
  isOpen: boolean;
  title?: string;
  message: string;
  handleClose: () => void;
  handleConfirm: () => void;
  handleUpdateNewMails?: (newMail: string) => void;
  isLoading?: boolean;
  toShowFooter?: boolean;
}

const ActionConfirmModal = ({
  isOpen,
  handleClose,
  title = "",
  message = "",
  handleConfirm,
  isLoading = false,
  toShowFooter = true,
}: Props) => {
  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <div className="row gx-0 p-0">
        <DialogTitle className="col-10"  dangerouslySetInnerHTML={{ __html: title }} />
        <div className="col-2 d-flex justify-content-end ">
          <FontAwesomeIcon
            className="pointer mt-1"
            icon={faClose}
            size="2x"
            onClick={() => {
              handleClose();
            }}
          />
        </div>
      </div>
      <DialogContent className="p-0 mb-2">
        <DialogContentText id="alert-dialog-slide-description">
          <div className="text-center fs-3" dangerouslySetInnerHTML={{ __html: message }} />
        </DialogContentText>
      </DialogContent>
      {toShowFooter && (
        <DialogActions className="border-top border-dark">
          <Button
            className="btn"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            No
          </Button>
          <LoaderButton
            isLoading={isLoading}
            className="btn"
            label="Yes"
            handleClick={() => {
              handleConfirm();
            }}
          />
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ActionConfirmModal;


const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});