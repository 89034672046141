import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'


interface Props{
    id: string;
    name:string;
    formControlClassName?:string;
    selectClassName?:string;
    labelClassName?:string;
    menuClassName?:string;
    value?:string | number | null;
    defaultValue?: any;
    handleChange?:(event: SelectChangeEvent<unknown>) => void;
    error?:any;
    readonly?:boolean;
    disabled?:boolean;
    shrink?:boolean;
    required?:boolean;
    label:string;
    handleOnClick?:(value : any)=>void
    autoComplete?:string;
    startAdornment?:any;
    endAdornment?:any;
    menuList:any;
    input?:any;
}
const SelectInputField = ({id,name,label,selectClassName,labelClassName,formControlClassName,menuClassName,value, defaultValue,menuList,handleChange,handleOnClick=(_value : any) => null,required = false,input,disabled} : Props) => {
  return (
    <FormControl className={formControlClassName}  fullWidth={formControlClassName ? false : true}>
      <InputLabel
        id="select-label"
        size="small"
        className={labelClassName}
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontSize: "14px",
          fontFamily: "SenticosansDTCond Thin",
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId="select-label"
        className={selectClassName}
        id={id}
        name={name}
        defaultValue={defaultValue}
        value={value}
        onChange={handleChange}
        label={label}
        size="small"
        input={input}
        required
        disabled={disabled}
      >
        {menuList.map((menu : any) => {
          return (
            <MenuItem key={menu} className={menuClassName} value={menu} onClick={() => handleOnClick!(menu)}>
              {menu}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default SelectInputField