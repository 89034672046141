import { faClose, faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectProjectId } from "../../redux/selectors/selectors";
import { generatePdf, getSubListForProject } from "../../utils/api";
import SelectInputField from "../SelectInputField/selectInputField";
import {
  BidReportHeaderCells,
  BidReportStatus,
  ModalStyle,
} from "../../utils/const";
import { CustomTable } from "../DataTable";
import { SearchFilteredSubListType } from "../../utils/types";
import { SelectChangeEvent } from "@mui/material";

const initialSearchFilteredSubList: SearchFilteredSubListType = {
  bidReposnse: [],
  laborAffiliation: "",
  classification: { id: null, classificationName: "", classificationCode: "" },
  isBidSubmitted: false,
};

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  title:string;
}

const BidReportModal = ({ isOpen = false, handleClose,title }: Props) => {
  const [status, setStatus] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState([]);

  const projectId = useSelector(selectProjectId);

  useEffect(() => {
    if (isOpen) {
      fetchGetSubListForProject(initialSearchFilteredSubList);
    } else {
      setStatus("All");
    }
  }, [isOpen]);

  const fetchGetSubListForProject = (body: SearchFilteredSubListType) => {
    getSubListForProject("", projectId, body)
      .then((resp) => {
        if (resp === null || resp.data === new Map()) {
          return;
        }
        console.log("create", creatRowStructure(resp.data.selectedSubs));
        creatRowStructure(resp.data.selectedSubs);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const creatRowStructure = (responseData: any) => {
    let tempRows: any = [];
    responseData.forEach((data: any) => {
      const tradeCode = data.tradeCode;
      tempRows = [
        ...tempRows,
        ...data?.subsList.map(
          (sub: {
            firstName: string;
            lastName: string;
            orgName: string;
            email: string;
            status: string;
            invitedDate: string;
            actionDate: string;
          }) => ({
            tradeCode: tradeCode,
            subEmail: sub.email,
            subName:
              `${sub.firstName} ${sub.lastName}` === "null null"
                ? null
                : `${sub.firstName} ${sub.lastName}`,
            orgName: sub.orgName,
            status: sub.status,
            invitedDate: sub.invitedDate,
            actionDate: sub.actionDate,
          })
        ),
      ];
      console.log("tempRows", tempRows);
    });
    setRows(tempRows);
  };

  const handleGeneratePdfCall = () => {
    setIsLoading(true);
    const body = {
      bidReposnse: status === "All" ? [] : [status],
      laborAffiliation: "",
      classification: {
        id: null,
        classificationName: "",
        classificationCode: "",
      },
      isBidSubmitted: false,
    };

    generatePdf("", projectId, {
      bidReposnse: status === "All" ? [] : [status],
      laborAffiliation: "",
      classification: {
        id: null,
        classificationName: "",
        classificationCode: "",
      },
      isBidSubmitted: false,
    })
      .then((resp) => {
        if (resp.status === "OK" && resp.data.url) {
          const binaryString = atob(resp.data.url);
          const binaryLen = binaryString.length;
          const bytes = new Uint8Array(binaryLen);

          for (let i = 0; i < binaryLen; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }

          const blob = new Blob([bytes], {
            type: "application/pdf",
          });
          const url = window.URL.createObjectURL(blob);

          const link = document.createElement("a");
          link.href = url;
          link.download = resp.data.title;
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal
      id="viewInvite"
      key="invite"
      className="position-fixed m-2"
      isOpen={isOpen}
      ariaHideApp={false}
      style={ModalStyle}
    >
      <div className="modal-dialog modal-2x">
        <div className="modal-content justify-content-center">
          <div className="modal-header row gx-0">
            <div className="col-6">
              <h5 className="fw-bold">{title} Report</h5>
            </div>
            <div className="col-6 d-flex justify-content-end">
              <FontAwesomeIcon
                className="col-2 pointer"
                icon={faClose}
                size="2xl"
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </div>
          <div
            className="modal-body"
            style={{
              width: `calc(${window.innerWidth}px - 30vw)`,
              height: "60vh",
            }}
          >
            <div className="container">
              <div className="d-flex gap-3">
                <SelectInputField
                  id="status"
                  name="status"
                  label="Status"
                  formControlClassName="col-4"
                  selectClassName="bg-white"
                  menuClassName="d-block ms-2"
                  value={status}
                  handleChange={(e: SelectChangeEvent<unknown>) => {
                    const status = e.target.value as string;
                    setStatus(status);
                    fetchGetSubListForProject({
                      bidReposnse: status === "All" ? [] : [status],
                      laborAffiliation: "",
                      classification: {
                        id: null,
                        classificationName: "",
                        classificationCode: "",
                      },
                      isBidSubmitted: false,
                    });
                  }}
                  menuList={BidReportStatus}
                />
                <FontAwesomeIcon
                  className="mt-1 theme-color pointer"
                  icon={faDownload}
                  onClick={() => {
                    handleGeneratePdfCall();
                  }}
                  size="2x"
                />
              </div>
              <div className="row gx-0 mt-3 mb-3" >
                <CustomTable
                  rows={rows}
                  headCells={BidReportHeaderCells}
                  isLoading={isLoading}
                  maxHeight={300}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default BidReportModal;
