import { faClose, faExpand, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress } from '@mui/material';
import Modal from "react-modal";
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { selectThemeColor } from "../../../redux/selectors/selectors";
import InputField from '../../InputField';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { VisuallyHiddenInput } from '../../../customeTheme';
import SelectInputField from '../../SelectInputField/selectInputField';
import AttachFileList from '../../AttachFileList/attachFileList';
import { DataUtil } from '../../../utils/dataUtils';

interface Props{
    isOpen:boolean;
    handleClose: () => void;
    handleSubmit: (sendQueMailType : SendQueMailType) => void;
    isLoading: boolean;
    isViewOnly?:boolean;
}

type SendQueMailType = {
  sector: string;
  subject: string;
  emailBody: string;
  files: {
    name: string;
    files: File[];
  }[];
};

const AskQuestionsModal = ({
  isOpen = false,
  handleClose,
  handleSubmit,
  isLoading = false,
  isViewOnly=true
}: Props) => {
  const [minimize, setMinimize] = useState<boolean>(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [sendQueMail, setSendQueMail] = useState<SendQueMailType>({
    sector: "Private",
    subject: "",
    emailBody: "",
    files: [],
  });

  const color = useSelector(selectThemeColor);

  const handleChange = (event: any) => {
    const { value, name } = event.target;
    setSendQueMail({
      ...sendQueMail,
      [name]: value,
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>)=>{
    const files = event.target.files;
    console.log("files", files);
    if (files) {
      setSendQueMail({
        ...sendQueMail,
        files: DataUtil.createAttachmentFile(files),
      });
      setSelectedFiles((prevRows: any) => [...prevRows, ...DataUtil.createAttachmentFile(files)]);
    }
  };

  const handleFileInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
    event.currentTarget.value = "";
  };

  //ask question mail send related functions:
  const handleRemoveSelectedFile = (index: number) => {
    let removeSelectedFiles = [...selectedFiles].filter(
      (file: File, fileIndex : number) => fileIndex !== index
    );
    setSelectedFiles(removeSelectedFiles);
    setSendQueMail({
      ...sendQueMail,
      files: DataUtil.createAttachmentFile(removeSelectedFiles),
    });
  };

  return (
    <Modal
      id="viewInvite"
      className="position-fixed m-2"
      isOpen={isOpen}
      ariaHideApp={false}
      style={{
        content: {
          position: "fixed",
          top: "auto",
          left: "auto",
          right: minimize ? "10px" : "0",
          bottom: minimize ? "10px" : "0",
          width: minimize ? "500px" : "500px",
          height: minimize ? "45px" : "535px",
          padding: minimize ? "0px" : "0",
          borderRadius: "10px",
          backgroundColor: "white",
          border: "1px solid #ccc",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
          transition: "width 0.3s ease, height 0.3s ease",
        },
        overlay: {
          backgroundColor: minimize ? "transparent" : "rgba(0, 0, 0, 0.7)",
          zIndex: !minimize ? 0 : 1000,
          pointerEvents: !minimize ? "auto" : "none",
          transition: "background-color 0.3s ease, opacity 0.3s ease",
          opacity: 1,
        },
      }}
    >
      <div className="modal-dialog modal-2x" style={{ width: "500px" }}>
        <div className="modal-content justify-content-center">
          <div className="modal-header row gx-0">
            <div className="col-6"></div>
            <div className="col-4 offset-2">
              <FontAwesomeIcon
                className="col-2 offset-4 pointer"
                icon={minimize ? faExpand : faMinus}
                size="xl"
                color={color.mediumColor}
                onClick={() => setMinimize(!minimize)}
              />
              <FontAwesomeIcon
                className="col-2 pointer"
                icon={faClose}
                size="xl"
                color={color.mediumColor}
                onClick={() => {
                  setMinimize(false);
                  setSelectedFiles([]);
                  handleClose();
                }}
              />
            </div>
          </div>
          {!minimize && (
            <div
              className="modal-body"
              style={{ width: "500px", height: "400px", overflowY: "scroll" }}
            >
              <div className="row gx-0 px-3">
                <SelectInputField
                  id="sector"
                  name="sector"
                  label="Select Privacy Type"
                  formControlClassName='col-4 mt-3'
                  labelClassName='bg-white'
                  selectClassName='bg-white'
                  menuClassName='d-block ms-2'
                  menuList={["", "Public", "Private"]}
                  handleChange={handleChange}
                />
              </div>
              <div className="row m-1 mt-3">
                <InputField
                  className="form-control bg-white col-12"
                  id="subject"
                  type="text"
                  name="subject"
                  label="subject"
                  handleChange={handleChange}
                />
              </div>
              <div className="row m-1 mt-3">
                <InputField
                  id="emailBody"
                  name="emailBody"
                  type="text"
                  className="form-control col-12 mt-2"
                  label="Add Details"
                  multiline
                  rows={10}
                  handleChange={handleChange}
                />
              </div>
             <AttachFileList selectedFiles={selectedFiles} handleRemoveSelectedFile={handleRemoveSelectedFile}/>
            </div>
          )}
          {!minimize && (
            <div className="footer">
              <div className="row gx-0">
                <div className="col-5 ms-2">
                  <Button
                    className="btn border"
                    component="label"
                    role={undefined}
                    variant="text"
                    tabIndex={-1}
                    size="small"
                  >
                    <AttachFileIcon className="fs-4" color="inherit" />
                    Attach files
                    <VisuallyHiddenInput
                      multiple
                      type="file"
                      onChange={handleFileChange}
                      onClick={handleFileInputClick}
                    />
                  </Button>
                </div>
                <div className="col-3 offset-3 gap-2">
                  <Button
                    className="btn col-12 ms-4"
                    onClick={() => {
                      handleSubmit(sendQueMail);
                      setSelectedFiles([]);
                    }}
                    disabled={
                      sendQueMail.subject === "" || sendQueMail.emailBody === ""
                    }
                  >
                    {isLoading ? (
                      <CircularProgress
                        className="ms-1"
                        color="info"
                        variant="indeterminate"
                        thickness={2}
                        size={26}
                      />
                    ) : (
                      "Send"
                    )}
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default AskQuestionsModal;
