import { useState } from "react";
import MyBidsTable from "./MyBidsTable/myBidsTable";
import { Button } from "@material-ui/core";
import BidExploreOpportunities from "./BidExploreOpportunities/bidExploreOpportunities";
import { SubmenuEnum } from "../../utils/enum";
import { useDispatch } from "react-redux";
import {
  setPostProjectRespStatus,
  setProjectId,
} from "../../redux/actions/projectFormActions";
import ProjectBids from "./ProjectBids/ProjectBids";
import InvitedBidDetailForm from "./InvitedBidDetailForm/invitedBidDetailForm";
import { setToNavigateMenu } from "../../redux/actions/actions";
import { useSelector } from "react-redux";
import { selectToNavigate } from "../../redux/selectors/selectors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock } from "@fortawesome/free-solid-svg-icons";
import UsePermission from "../../hooks/usePermission";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Alert, Box, Snackbar } from "@mui/material";

const MyBidsInternal = () => {
  const [selectedRow, setSelectedRow] = useState<any>([]);
  const [bidId, setBidId] = useState(0);
  const [open, setOpen] = useState(false);

  const {isSubContractor} = UsePermission();

  const dispatch = useDispatch();
  const activeMenu = useSelector(selectToNavigate);

  const handleSelectRow = (row: any) => {
    if (row.bidId) {
      setBidId(row.bidId);
    }
    setSelectedRow(row);
    dispatch(setProjectId(row.projectId));
  };

  const handleMyBids = () => {
    dispatch(setToNavigateMenu(SubmenuEnum.MY_BIDS));
  };

  const handlePostBids = () => {
    if (isSubContractor) {
      setOpen(true);
      return;
    }
    dispatch(setToNavigateMenu(SubmenuEnum.POST_BIDS));
    setSelectedRow([]);
    dispatch(setProjectId(undefined));
    dispatch(setPostProjectRespStatus(""));
  };

  const handleBidOpportClick = () => {
    dispatch(setToNavigateMenu(SubmenuEnum.BID_OPPORTUNITIES_NETWORK));
  };

  const handleOnSelectCell = (cell: any) => {
    if (cell.length === 0) {
      dispatch(setToNavigateMenu(SubmenuEnum.EDIT_PROJECT));

    } else {
      dispatch(setToNavigateMenu(SubmenuEnum.INVITED_BIDS));

    }
  };

  const renderSubMenu = () => {
    switch (activeMenu) {
      case SubmenuEnum.BID_OPPORTUNITIES_NETWORK:
        return <BidExploreOpportunities />;
      case SubmenuEnum.MY_BIDS:
        return (
          <MyBidsTable
            onSelectProjectRow={handleSelectRow}
            bidId={bidId}
            onSelectCell={handleOnSelectCell}
          />
        );
      case SubmenuEnum.EDIT_PROJECT:
      case SubmenuEnum.POST_BIDS:
        return (
          <ProjectBids
            projectName={selectedRow.projectName}
            setBackButton={() =>
              dispatch(setToNavigateMenu(SubmenuEnum.MY_BIDS))
            }
          />
        );
      case SubmenuEnum.INVITED_BIDS:
        return (
          <InvitedBidDetailForm
            status={selectedRow.status}
            setBackButton={() =>
              dispatch(setToNavigateMenu(SubmenuEnum.MY_BIDS))
            }
            bidId={bidId}
          />
        );
      default:
        return (
          <MyBidsTable
            onSelectProjectRow={handleSelectRow}
            bidId={bidId}
            onSelectCell={handleOnSelectCell}
          />
        );
    }
  };

  return (
    <>
      <div className="row gx-0 mt-2 gap-4">
        <div className="col-lg-1 col-md-2 col-sm-12">
          <Button
            className="btn main-btn w-100"
            style={{
              opacity:
                activeMenu === SubmenuEnum.MY_BIDS ||
                activeMenu === SubmenuEnum.EDIT_PROJECT ||
                activeMenu === SubmenuEnum.INVITED_BIDS
                  ? 1
                  : 0.7,
            }}
            onClick={handleMyBids}
          >
            My Bids
          </Button>
        </div>
        <div className="col-lg-1 col-md-2 col-sm-12">
          <Button
            className="btn main-btn w-100"
            style={{
              opacity: activeMenu === SubmenuEnum.POST_BIDS ? 1 : 0.7,
            }}
            onClick={handlePostBids}
          >
            Post Bid{" "}
            {isSubContractor ? (
              <FontAwesomeIcon className="ms-1" icon={faLock} size="sm" />
            ) : (
              <></>
            )}
          </Button>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12">
          <Button
            className="btn main-btn w-100"
            style={{
              opacity:
                activeMenu === SubmenuEnum.BID_OPPORTUNITIES_NETWORK ? 1 : 0.7,
            }}
            onClick={handleBidOpportClick}
          >
            Bid Opportunities Network
          </Button>
        </div>
      </div>
      <div
        className="overflow-hidden w-100"
        style={{
          height: activeMenu === SubmenuEnum.POST_BIDS ? "95vh" : " 89vh",
        }}
      >
        {renderSubMenu()}
      </div>

      <Snackbar
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          severity="warning"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => setOpen(false)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          You do not have permission to access this resource. Please contact
          your administrator for assistance or check your access privileges.{" "}
        </Alert>
      </Snackbar>
    </>
  );
};

export default MyBidsInternal;
