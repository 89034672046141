import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClose,
  faEdit,
  faEnvelopeCircleCheck,
  faEye,
  faTrash,
  faMinus,
  faDownload,
  faArrowCircleLeft,
  faDeleteLeft,
  faUsersViewfinder,
} from "@fortawesome/free-solid-svg-icons";
import {
  Button,
  Checkbox,
  FormControlLabel,
  OutlinedInput,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useSelector } from "react-redux";
import {  selectProjectId, selectThemeColor } from "../../redux/selectors/selectors";
import { Link } from "react-router-dom";
import { forwardRef, memo, useEffect, useState } from "react";
import SelectInputField from "../SelectInputField/selectInputField";
import { ModalStyle, MyBidStatus, btnGrpForBidStatus } from "../../utils/const";
import InputField from "../InputField";
import Modal from "react-modal";
import { FilePreview } from "../FilePreview";
import { StyledTableCell } from "../../customeTheme";
import { PublicBidAccessEnum } from "../../utils/enum";
import LoaderButton from "../Loader/loaderButton";

const defaultFile = new File(["file content"], "example.txt", {
  type: "text/plain",
});

interface Props {
  headCells: any[];
  row: any;
  rowIndex: any;
  onSelectRowClick?: (row: any, actionType: string, checked: boolean) => void;
  handleCheck?: (row: any, checked: boolean) => void;
  onClickGetCell?: (cell: any) => void;
  handelRemoveTrade?: (id: number, actionTaken: string) => void;
  handleSetsubContractorMails?: (subContractorMails: string[]) => void;
  onDeleteRowClick?: (index: any) => void;
  onViewRow?: (index: any) => void;
  handleChangeDropDownStatus?: (id: number, status: string) => void;
  handleSelectTradeId?: (selectTradeId: string[]) => void;
  handleDelete?: (projectDocId: number) => void;
  handleDocsStatus?: (projectDocId: any, status: string) => void;
  selectTradeId?: any;
  isEdit?: boolean;
  isLoading?: boolean;
  selected?:any;
}

const RowDataTable = memo(({
  headCells,
  row,
  rowIndex,
  onSelectRowClick,
  handleCheck = () => {
    return;
  },
  onClickGetCell = () => {
    return;
  },
  handelRemoveTrade,
  onDeleteRowClick,
  handleChangeDropDownStatus,
  handleSelectTradeId,
  handleDelete,
  handleDocsStatus = (_projectDocId: any, _status: string) => null,
  selectTradeId,
  isEdit = false,
  isLoading=false,
  selected=[]
}: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>("");
  const [modalIsOpen, setIsOpen] = useState(false);
  const [viewFile, setViewFile] = useState<File>(defaultFile);
  const [csvHeaders, setCsvHeaders] = useState<any[]>([]);
  const [csvRows, setCsvRows] = useState<any[]>([]);

  const color = useSelector(selectThemeColor);
  const projectId = useSelector(selectProjectId);

  type docType = "pdf" | "csv" | "docx" | "jpeg" | "jpg" | "png"; // Add more statuses as needed

  const bobType: Record<docType, string> = {
    pdf: "application/pdf",
    csv: "application/csv",
    docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    jpeg: "image/jpeg",
    jpg: "image/jpeg",
    png: "image/jpeg",
  };

  const renderPublicButton = (
    onlineAccess: string,
    selectedProjectId: number,
    cellId: string | number
  ) => {
    switch (onlineAccess) {
      case PublicBidAccessEnum.REQUEST_REQUIRED:
        return (
          <LoaderButton
            isLoading={isLoading && selectedProjectId === projectId}
            className="btn p-1"
            label={cellId as string}
            handleClick={() => onSelectRowClick!(row, "edit", false)}
          />
        );
      case PublicBidAccessEnum.NO_REQUEST:
        return (
          <Button
            className="btn p-1"
            style={{ background: color.mediumColor, color: "#fff" }}
            onClick={() => onSelectRowClick!(row, "view", false)}
          >
            <span className="size-sm">View</span>
          </Button>
        );

      default:
        break;
    }
  };


  const renderTableCell = (cell: any, row: any, index: number) => {
    switch (cell.action) {
      case "check": {
        console.log("check", row.status);
        return (
          <StyledTableCell key={cell.id} align="center">
            {
              <Checkbox
                style={{ color: color.darkColor }}
                key={index}
                size="small"
              />
            }
          </StyledTableCell>
        );
      }
      case "click": {
        const isSpecialStatus = ["Rejected", "Bid Submitted", "Draft"].includes(
          row.status
        );
        const isResponseCell = headCells[0].id === "response";
        return isSpecialStatus && isResponseCell ? (
          <StyledTableCell sx={{ overflowWrap: "break-word" }} key={cell.id}>
            <span> {row[cell.id]}</span>
          </StyledTableCell>
        ) : (
          <StyledTableCell
            sx={{ cursor: "pointer", overflowWrap: "break-word" }}
            key={cell.id}
            onClick={() => {
              onSelectRowClick!(row, "click", false);
              onClickGetCell!(
                headCells.filter((cell) => cell.id === "invitedBy")
              );
            }}
          >
            <Link
              className="text-truncate"
              style={{ color: "primary", textDecoration: "none" }}
              to={""}
            >
              {row[cell.id]}
            </Link>
          </StyledTableCell>
        );
      }
      case "openModal": {
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            key={cell.id}
            onClick={() => handleOpenModal(row)}
          >
            {row[cell.id]}
          </StyledTableCell>
        );
      }

      case "date": {
        const cellAlign = cell.id === "addendaDate" ? "center" : "left";
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            align={cellAlign}
            key={cell.id}
          >
            {row[cell.id] ? row[cell.id] : <FontAwesomeIcon icon={faMinus} />}
          </StyledTableCell>
        );
      }

      case "phone": {
        return (
          <StyledTableCell
            className="text-truncate"
            sx={{ overflowWrap: "break-word" }}
            align="left"
            key={cell.id}
          >
            {row[cell.id] ? (
              row[cell.id]
                .replace(/\D/g, "")
                .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3")
            ) : (
              <></>
            )}
          </StyledTableCell>
        );
      }

      case "isRowActionable": {
        return (
          <StyledTableCell key={cell.id}>
            <Tooltip
              title={
                <>
                  <div className="tooltip-title">
                    <span>View Bid</span>
                  </div>
                </>
              }
            >
              <div className="d-flex jusstify-content-center ms-2 gap-2">
                <img
                  style={{ cursor: "pointer" }}
                  src="/Action-arrow.svg"
                  alt="Action Arrow"
                  onClick={() => {
                    onSelectRowClick!(row, "edit", false);
                  }}
                />
              </div>
            </Tooltip>
          </StyledTableCell>
        );
      }
      case "edit": {
        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            <FontAwesomeIcon
              icon={faEdit}
              size="lg"
              onClick={() => {
                onSelectRowClick!(row, "edit", false);
              }}
              title="Edit"
            />
          </StyledTableCell>
        );
      }
      case "button": {
        const publicBidStatus = row["publicBidStatus"];
        const onlineAccessStatus = row["status"];
        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            {!publicBidStatus ? (
              renderPublicButton(onlineAccessStatus, row.projectId, cell.id)
            ) : (
              <span className="size-sm">
                {publicBidStatus.charAt(0).toUpperCase() +
                  publicBidStatus.slice(1)}
              </span>
            )}
          </StyledTableCell>
        );
      }
      case "dropDown": {
        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            <SelectInputField
              id="status"
              name="status"
              label="Select Status"
              formControlClassName="col-12 p-1"
              labelClassName="col-12"
              selectClassName="bg-white"
              menuClassName="d-block ms-2"
              value={row.status}
              handleChange={(e) =>
                handleStatusChange(row.projectId, e.target.value)
              }
              handleOnClick={(_value: any) => null}
              menuList={MyBidStatus}
            />
          </StyledTableCell>
        );
      }
      case "inviteDropDown": {
        const showFlag = row.status === "Bid Submitted";
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            key={cell.id}
            align="right"
          >
            <SelectInputField
              id="status"
              name="status"
              label="Select Status"
              formControlClassName="col-12 p-1"
              labelClassName="col-12 mt-1 bg-white"
              selectClassName="bg-white"
              menuClassName="d-block ms-2"
              defaultValue={row.status || "Invited"}
              handleChange={(e: { target: { value: any } }) =>
                handleStatusChange(row.projectId, e.target.value)
              }
              menuList={["Accepted", "Rejected", "May Be", "Invited"]}
              input={
                showFlag ? (
                  <InputField
                    className="form-control bg-white col-12"
                    id="subject"
                    type="text"
                    name="subject"
                    label=""
                    value={row.status}
                    readonly
                    shrink
                  />
                ) : (
                  <OutlinedInput label="Status" />
                )
              }
            />
          </StyledTableCell>
        );
      }

      case "bidStatusUpdate": {
        return (
          <>
            <StyledTableCell align="right">
              <div className="d-flex">
                {btnGrpForBidStatus.map((button: any, index: number) => {
                  return (
                    <>
                      {!(
                        row["status"] === "Draft" ||
                        row["status"] === "Bid Submitted"
                      ) && (
                        <FormControlLabel
                          control={
                            <>
                              <Checkbox
                                key={index}
                                size="small"
                                value={
                                  button.value !== row.status
                                    ? button.value
                                    : "Invited"
                                }
                                checked={row.status === button.value}
                                className="col-3 ms-1"
                                onChange={(e) => {
                                  handleCheck!(
                                    {
                                      id: row.id,
                                      tradeName: row.tradeName,
                                      tradeCode: row.tradeCode,
                                      status: e.target.value,
                                    },
                                    e.target.checked
                                  );
                                }}
                              />
                              <span className="m-1 mt-1">{button.label}</span>
                            </>
                          }
                          label=""
                        />
                      )}
                    </>
                  );
                })}
              </div>
              {(row["status"] === "Bid Submitted" ||
                row["status"] === "Draft") && (
                <label className="row gx-0">{row["status"]}</label>
              )}
            </StyledTableCell>
          </>
        );
      }
      case "addRemove": {
        return (
          <>
            <StyledTableCell>
              {row["bidRaised"] ? (
                <span>Bid Submitted</span>
              ) : (
                <Button
                  className="btn"
                  style={{ background: color.darkColor, color: "white" }}
                  onClick={() => onSelectRowClick!(row, "click", true)}
                >
                  {row["isAdded"] === "Y" ? "Remove" : "Add"}
                </Button>
              )}
            </StyledTableCell>
          </>
        );
      }
      case "subRequestAccess": {
        return (
          <>
            <StyledTableCell className="p-0">
              {row["status"] === "Pending" ? (
                <div className="d-flex gap-2">
                  <>
                    <Button
                      className="btn p-0"
                      style={{ background: color.darkColor, color: "white" }}
                      onClick={() => onSelectRowClick!(row, "accepted", true)}
                    >
                      Accept
                    </Button>
                    <Button
                      className="btn p-0"
                      style={{ background: color.darkColor, color: "white" }}
                      onClick={() => onSelectRowClick!(row, "declined", true)}
                    >
                      Decline
                    </Button>
                  </>
                </div>
              ) : (
                row["status"].toUpperCase()
              )}
            </StyledTableCell>
          </>
        );
      }
      case "companyHover": {
        return (
          <StyledTableCell key={cell.id}>
            <Tooltip
              title={
                <>
                  <div className="tooltip-title size-lg">
                    <span className="row gx-0">
                      Company Name: {row["orgName"]}
                    </span>
                    <span className="row gx-0">
                      Contact Number: {row["contactNumber"]}
                    </span>
                    <span className="row gx-0">Email: {row["email"]}</span>
                    <span className="row gx-0">
                      Classification: {row["classification"]}
                    </span>
                    <span className="row gx-0">
                      Labor Affiliation: {row["laborAffiliation"]}
                    </span>
                  </div>
                </>
              }
              arrow={true}
              enterDelay={1000}
            >
              <div className="d-flex jusstify-content-center ms-2 gap-2">
                {row[cell.id]}
              </div>
            </Tooltip>
          </StyledTableCell>
        );
      }
      case "tradeCheckBox": {
        return (
          <StyledTableCell key={cell.id}>
            <Checkbox
              style={{ color: color.darkColor, width: "70px" }}
              key={index}
              checked={
                Array.isArray(selectTradeId) &&
                selectTradeId.map((value: any) => value).includes(row["id"])
              }
              onChange={(event) => {
                event.preventDefault();
                if (event.target.checked) {
                  let tempTradeId: any[] = [...selectTradeId, row["id"]];
                  handleSelectTradeId!(tempTradeId);
                } else {
                  let tempTradeId: any[] = selectTradeId;
                  tempTradeId = tempTradeId.filter(
                    (id: any) => id !== row["id"]
                  );
                  handleSelectTradeId!(tempTradeId);
                }
                handleCheck!(row, event.target.checked);
              }}
            />
          </StyledTableCell>
        );
      }

      case "removeTrade": {
        return (
          <StyledTableCell sx={{ cursor: "pointer" }} key={cell.id}>
            <div className="d-flex ms-2 gap-3">
              <Tooltip
                title={
                  <>
                    <div className="tooltip-title">
                      <span>
                        {row.actionTaken === "Deleted"
                          ? "Restore from Bin"
                          : "Remove"}
                      </span>
                    </div>
                  </>
                }
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  size="lg"
                  onClick={() => {
                    handelRemoveTrade!(row.id, row);
                  }}
                />
              </Tooltip>
            </div>
          </StyledTableCell>
        );
      }
      case "tradeName": {
        return (
          <StyledTableCell
            style={{
              maxWidth: 100,
              overflow: "hidden",
            }}
            align="left"
            padding="normal"
            key={cell.id}
          >
            {row[cell.id] ? row[cell.id] : <span className="ms-3">-</span>}
          </StyledTableCell>
        );
      }
      case "deleteOrViewSubmitBidsDetails": {
        return (
          <StyledTableCell key={cell.id}>
            {row["status"] === "Bid Submitted" ? (
              // <Tooltip
              //   title={
              //     <>
              //       <div className="tooltip-title">
              //         <span>View Bid Details</span>
              //       </div>
              //     </>
              //   }
              // >
              <div className="d-flex jusstify-content-center ms-1 gap-2">
                {/* <img
                    style={{ cursor: "pointer" }}
                    src="/Action-arrow.svg"
                    alt="Action Arrow"
                    onClick={() => {
                      onSelectRowClick!(row, "viewBidDetails", false);
                    }}
                  /> */}
                <FontAwesomeIcon
                  onClick={() => {
                    onSelectRowClick!(row, "viewBidDetails", false);
                  }}
                  className="theme-color pointer"
                  icon={faEye}
                  title="View Bid Details"
                  size="lg"
                />
                <FontAwesomeIcon
                  onClick={() => {
                    onSelectRowClick!(row, "deleteBid", false);
                  }}
                  className="text-danger pointer"
                  icon={faTrash}
                  title="Delete Bid Details"
                  size="lg"
                />
              </div>
            ) : (
              <div className="d-flex jusstify-content-center ms-1">
                <FontAwesomeIcon
                  onClick={() => {
                    onSelectRowClick!(row, "deleteBid", false);
                  }}
                  className="text-danger pointer"
                  icon={faTrash}
                  size="lg"
                />
              </div>
            )}
          </StyledTableCell>
        );
      }
      case "viewAndDelete": {
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            key={cell.id}
            align="center"
          >
            <div className="opacity-50">
              <Tooltip
                title={
                  <>
                    <div className="tooltip-title">
                      <span>View</span>
                    </div>
                  </>
                }
              >
                <FontAwesomeIcon
                  icon={faEye}
                  size="lg"
                  onClick={() =>
                    handleView(
                      row["fileDataBase64"],
                      row["docName"],
                      row["projectDocId"]
                    )
                  }
                />
              </Tooltip>
              <Tooltip
                title={
                  <>
                    <div className="tooltip-title">
                      <span>Download</span>
                    </div>
                  </>
                }
              >
                <FontAwesomeIcon
                  className="ms-2"
                  icon={faDownload}
                  size="lg"
                  onClick={() =>
                    handleDownload(
                      row["fileDataBase64"],
                      row["docName"],
                      row["projectDocId"]
                    )
                  }
                />
              </Tooltip>
              {isEdit ? (
                <Tooltip
                  title={
                    <>
                      <div className="tooltip-title">
                        <span>Delete</span>
                      </div>
                    </>
                  }
                >
                  <FontAwesomeIcon
                    className="ms-2"
                    icon={faTrash}
                    size="lg"
                    onClick={() => {
                      handleDelete!(row["projectDocId"]);
                    }}
                  />
                </Tooltip>
              ) : (
                <></>
              )}
            </div>
          </StyledTableCell>
        );
      }

      case "viewAndDownload": {
        console.log(cell.action);
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            key={cell.id}
            align="center"
          >
            <div className="opacity-50">
              <Tooltip
                title={
                  <>
                    <div className="tooltip-title">
                      <span>View</span>
                    </div>
                  </>
                }
              >
                <FontAwesomeIcon
                  icon={faEye}
                  size="lg"
                  onClick={() =>
                    handleView(
                      row["fileDataBase64"],
                      row["docName"],
                      row["projectDocId"]
                    )
                  }
                />
              </Tooltip>
              <Tooltip
                title={
                  <>
                    <div className="tooltip-title">
                      <span>Download</span>
                    </div>
                  </>
                }
              >
                <FontAwesomeIcon
                  className="ms-2"
                  icon={faDownload}
                  size="lg"
                  onClick={() =>
                    handleDownload(
                      row["fileDataBase64"],
                      row["docName"],
                      row["projectDocId"]
                    )
                  }
                />
              </Tooltip>
            </div>
          </StyledTableCell>
        );
      }

      case "size": {
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            key={cell.id}
            align="center"
          >
            {row[cell.id]}
          </StyledTableCell>
        );
      }
      case "title": {
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            key={index}
            align="center"
            style={{
              maxWidth: 200,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {row[cell.id].split(".")[0]}
          </StyledTableCell>
        );
      }
      case "addToSelf": {
        return (
          <StyledTableCell
            sx={{ cursor: "pointer" }}
            key={cell.id}
            align="center"
          >
            {/* <Button
              className="p-0"
            > */}
            <FontAwesomeIcon
              className="theme-color"
              onClick={() => onSelectRowClick!(row, "addToSelf", false)}
              color="secondary"
              maskId="arrow-alt-circle-left"
              title="Transfer to My Bids"
              icon={faArrowCircleLeft}
              size="2xl"
            />
            {/* </Button> */}
          </StyledTableCell>
        );
      }
      case "inviteCheck": {
        const isItemSelected = selected.some((selectedRow: { email: string; })=> selectedRow.email === row.email);
        return (
          <StyledTableCell key={cell.id} align="left">
            {row.status === "" || row.status === null ? (
              <Checkbox
                style={{ color: color.darkColor }}
                key={index}
                size="small"
                checked={isItemSelected}
                onClick={() => {
                  handleCheck!(row, !isItemSelected);
                }}
              />
            ) : (
              <span>{row.status}</span>
            )}
          </StyledTableCell>
        );
      }
      case "publicProjectHover": {
        return (
          <StyledTableCell
            align="left"
            onClick={() => onSelectRowClick!(row, "publicProjectHover", false)}
          >
            <Tooltip
              title={
                <>
                  <div className="col-12 p-0 tooltip-title size-sm">
                    <span className="row gx-0">
                      Dollar: {row["dollarSize"] || "NA"}
                    </span>
                    <span className="row gx-0">
                      Duration: {row["duration"] || "NA"}
                    </span>
                    <span className="row gx-0 d-flex">
                      Construction Type: {row["constructionType"] || "NA"}
                    </span>
                  </div>
                </>
              }
              arrow={true}
              enterDelay={500}
            >
              <div className="d-flex jusstify-content-center ms-2 gap-2">
                <Link
                  className="text-truncate"
                  style={{ color: "primary", textDecoration: "none" }}
                  to={""}
                >
                  {row[cell.id]}
                </Link>
              </div>
            </Tooltip>
          </StyledTableCell>
        );
      }
      default: {
        return (
          <StyledTableCell
            style={{
              maxWidth: 200, // Set the maximum width of the cell
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
            padding="normal"
            key={cell.id}
            align={cell.align ? cell.align : "center"}
          >
            {row[cell.id] ? row[cell.id] : <FontAwesomeIcon icon={faMinus} />}
          </StyledTableCell>
        );
      }
    }
  };

  const handleOpenModal = (data: any) => {
    setOpenModal(true);
    setSelectedRow(data);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleStatusChange = (id: any, status: any) => {
    handleChangeDropDownStatus!(id, status);
  };

  const base64ToBlob = (base64: string, mime: any) => {
    const sliceSize = 512;
    const byteCharacters = atob(base64);
    const bytesLength = byteCharacters.length;
    const slicesCount = Math.ceil(bytesLength / sliceSize);
    const byteArrays = new Array(slicesCount);

    for (let sliceIndex = 0; sliceIndex < slicesCount; sliceIndex++) {
      const begin = sliceIndex * sliceSize;
      const end = Math.min(begin + sliceSize, bytesLength);

      const bytes = new Array(end - begin);
      for (let offset = begin, i = 0; offset < end; offset++, i++) {
        bytes[i] = byteCharacters.charCodeAt(offset);
      }
      byteArrays[sliceIndex] = new Uint8Array(bytes);
    }
    return new Blob(byteArrays, { type: mime });
  };

  const parseCSV = async (text: string) => {
    const rows = text
      .trim()
      .split("\n")
      .filter((row) => row.length > 0);
    if (rows.length === 0) return;
    const newHeaders = rows[0].split(",").map((header) => header.trim());
    const structuredHeadersData = structuredHeaders(newHeaders);
    setCsvHeaders(structuredHeadersData);

    const CHUNK_SIZE = 1000;
    const newData: any[] = [];
    for (let i = 1; i < rows.length; i += CHUNK_SIZE) {
      const chunk = rows.slice(i, i + CHUNK_SIZE);
      const parsedChunk = chunk.map((row) => {
        const values = row.split(",").map((value) => value.trim());
        return newHeaders.reduce(
          (acc: Record<string, string>, header, index) => {
            acc[header] = values[index]?.replace(/[^a-zA-Z0-9 ]/g, "") || "";
            return acc;
          },
          {}
        );
      });
      newData.push(...parsedChunk);
    }
    const filteredData = newData.filter((row) =>
      structuredHeadersData.some((header: { id: string | number; }) => row[header.id])
    );

    setCsvRows(filteredData);
  };

  const structuredHeaders = (header: any) => {
    return (
      header &&
      header.map((cell: any, _index: any) => ({
        id: cell,
        name: cell,
        align:'left'
      }))
    );
  };

  const handleView = (
    fileDataBase64: any,
    docName: string,
    projectDocId: any
  ) => {
    let blob = new Blob();
    if (docName.split(".").pop() === "csv") {
      blob = base64ToBlob(fileDataBase64, bobType.csv);
      const file = new File([blob], docName, { type: blob.type });
      if (file) {
        const reader = new FileReader();
        reader.onload = (e: ProgressEvent<FileReader>) => {
          const text = e.target?.result as string;
          parseCSV(text);
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
        };
        reader.readAsText(file);
        setViewFile(file);
        setIsOpen(true);
      }
    } else {
      blob = base64ToBlob(
        fileDataBase64,
        Object.entries(bobType).find(
          ([key]) => key === docName.split(".").pop()
        )?.[1]
      );
      const file = new File([blob], docName, { type: blob.type });
      setViewFile(file);
      setIsOpen(true);
    }
    if (!isEdit) handleDocsStatus!(projectDocId, "Viewed");
  };
  const handleDownload = (
    fileDataBase64: any,
    docName: string,
    projectDocId: any
  ) => {
    try {
      const binaryString = atob(fileDataBase64);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);

      for (let i = 0; i < binaryLen; i++) {
        bytes[i] = binaryString.charCodeAt(i);
      }

      const blob = new Blob([bytes], { type: "application/octet-stream" });
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.download = docName;
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
      if (!isEdit) handleDocsStatus!(projectDocId, "Downloaded");
    } catch (error) {
      console.error("Failed to download file:", error);
    }
  };
  return (
    <>
      <TableRow className="text-break" hover  key={row.name} sx={{ borderBottom: "1" }}>
        {headCells.map((cell) => renderTableCell(cell, row, rowIndex))}
      </TableRow>
      <Modal
        id="viewDocument"
        isOpen={modalIsOpen}
        style={ModalStyle}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-2x">
          <div className="modal-content">
            <div className="modal-header mb-2">
              <div className="mx-auto fw-bold fs-3 opacity-70">View Document</div>
              <FontAwesomeIcon
                className="pointer"
                icon={faClose}
                size="2xl"
                aria-label="Close"
                onClick={handleClose}
              />
            </div>
            <div
              className="modal-body mt-4 overflow-hidden"
              style={{ width: "auto", height: "auto" }}
            >
              <div className="container">
                <div key="preview">
                  <FilePreview
                    file={viewFile}
                    headers={csvHeaders}
                    rows={csvRows}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        id="succes"
        isOpen={openModal}
        style={ModalStyle}
        ariaHideApp={false}
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div
              className="modal-header mb-1"
              style={{ borderBottom: "0.5px solid" }}
            >
              <div className="mx-auto">
                <FontAwesomeIcon
                  icon={faEnvelopeCircleCheck}
                  className="bg-succes opacity-50"
                  style={{ color: "#9bc569" }}
                  size="4x"
                />
              </div>
              <FontAwesomeIcon
                className="btn-close pointer"
                icon={faClose}
                size="lg"
                onClick={() => setOpenModal(false)}
              />
            </div>
            <div
              className="modal-body mt-5"
              style={{ width: "400px", height: "150px" }}
            >
              <div className="row">
                <InputField
                  id="email"
                  name="email"
                  value={selectedRow.email}
                  className="form-control py-1 form-control-lg"
                  type="text"
                  label="email"
                  required
                  handleChange={(e) => {
                    const email = e.target.value;
                    setSelectedRow({
                      ...selectedRow,
                      email,
                    });
                  }}
                />
              </div>
              <div className="row">
                <Button
                  className="btn col-6 mx-auto gx-5 mt-5"
                  style={{ backgroundColor: color.darkColor, color: "white" }}
                  onClick={(e) => {
                    e.preventDefault();
                    onSelectRowClick!(selectedRow, "click", false);
                    setOpenModal(false);
                  }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
});

export default RowDataTable;
