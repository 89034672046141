import { faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/material";
import Modal from "react-modal";
import { useSelector } from "react-redux";
import { selectProjectId } from "../../redux/selectors/selectors";
import InvitedBidDetailForm from "../MyBidsInternal/InvitedBidDetailForm/invitedBidDetailForm";

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  isLoading: boolean;
  handleAcceptRequest: (projectId: any, type:string) => void;
  title:string;
}

const PublicProjectViewModal = ({
  isOpen,
  handleClose,
  handleAcceptRequest,
  title
}: Props) => {

  const projectId = useSelector(selectProjectId);

  return (
    <Modal
      id="submitBid"
      isOpen={isOpen}
      style={{
        content: {
          width: "90vw",
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "auto",
          transform: "translate(-50%, -50%)",
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.6)",
          opacity: "1",
          background: "#f8f9fa",
          zIndex: "0",
          overflowX: "hidden",
        },
      }}
      ariaHideApp={false}
    >
      <div className="modal-header border-bottom">
        <div className="col-8">
          <h5 className="modal-title size-xl fw-bolder">{title}</h5>
        </div>
        <div className="col-4 d-flex justify-content-end">
          <FontAwesomeIcon
            className="btn-close theme-color fw-bolder pointer"
            icon={faClose}
            color="primary"
            size="xl"
            onClick={() => {
              handleClose();
            }}
          />
        </div>
      </div>
      <div
        className="modal-body"
        style={{
          width: `calc(${window.innerWidth}px - 15%)`,
          height: "65vh",
          overflow: "hidden",
        }}
      >
        <InvitedBidDetailForm />
      </div>
      <div className="row mt-1 gx-0">
        <div className="col-6 d-flex justify-content-end">
          <Button
            className="btn col-6"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
            }}
          >
            Cancel
          </Button>
        </div>
        <div className="col-6  d-flex justify-content-start">
          <Button
            className="btn col-6"
            onClick={(e) => {
              e.preventDefault();
              handleClose();
              handleAcceptRequest(projectId, "view");
            }}
          >
            Accept
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default PublicProjectViewModal;
