import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputField from "../../InputField";
import {
  createProject,
  GET_USA_STATE_AND_CITY,
  getProjectContact,
  getProjectDetails,
} from "../../..//utils/api";
import {
  selectIsPostProjectButtonVisible,
  selectProjectId,
} from "../../../redux/selectors/selectors";
import Utils from "./utils";
import { useSelector } from "react-redux";
import {
  BidType,
  BuildingType,
  ConstructionTypeList,
  MyBidStatus,
  PostBidStatus,
} from "../../../utils/const";
import { Button} from "@mui/material";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setPostProjectRespStatus } from "../../../redux/actions/projectFormActions";
import SelectInputField from "../../SelectInputField/selectInputField";
import { DatePickerField, TimePickerField } from "../../DateAndTimePickerField";

const BasicProjectDetailsForm = ({ setBackButton }: any) => {
  const {
    values,
    handleChange,
    errors: projectErrors,
    touched,
    setFieldValue,
    setFieldError,
    setTouched,
    isValid,
    resetForm,
  } = Utils();
  const sectorList = ["Residential", "Commercial","Public Agencies"];
  const accessStatus = ["Open to All - No Request", "Open to All - Request Required", "Invited Only"];
  const [showSnackBar, setShowsnackBar] = useState(false);
  const [message, setMessage] = useState("");
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [userNameList, setUserNameList] = useState<string[]>([]);
  const [projectBidStatus, setProjectBidStatus] = useState<string[]>([]);

  const dispatch = useDispatch()

  const toShowButton = useSelector(selectIsPostProjectButtonVisible);
  const projectId = useSelector(selectProjectId);

 /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    console.log("projectId ===>", projectId);
    if (projectId) {
      setIsEdit(true);
      getProjectDetails("", projectId, undefined)
        .then((response) => {
          console.log("response", response.data);
          if (response.status === "OK") {
            setIsEdit(true);
            dispatch(setPostProjectRespStatus(response.data.projectStatus));
            setFieldValue("projectName", response.data.projectName);
            setFieldValue(
              "projectNumber",
              response.data.projectNumber.toString()
            );
            setFieldValue("buildingSize", response.data.buildingSize);
            setFieldValue("siteSize", response.data.siteSize);
            setFieldValue("status", response.data.projectStatus);
            setFieldValue("projectContact", {id : null, firstName:response.data.projectContact});
            setFieldValue("siteSize", response.data.siteSize);
            setFieldValue("buildingCount", response.data.buildingCount);
            setFieldValue("storyCount", response.data.storyCount);
            setFieldValue("dollarSize", response.data.dollarSize);
            setFieldValue("duration", response.data.duration);
            setFieldValue("onlineAccess", response.data.onlineAccess);
            setFieldValue("buildingType", response.data.buildingType);
            setFieldValue("bidType", response.data.bidType);
            setFieldValue("storyCount", response.data.stories);

            setFieldValue(
              "jobWalkDate",
              response.data.jobWalkDate.split(" ")[0]
            );
            setFieldValue("jobWalkTime", response.data.jobWalkTime);
            setFieldValue("bidDate", response.data.bidDate.split(" ")[0]);
            setFieldValue("bidTime", response.data.bidTime);
            setFieldValue("zip", response.data.zipCode);
            setFieldValue("city", response.data.city);
            setFieldValue("state", response.data.state);
            setFieldValue("addressOne", response.data.address1);
            setFieldValue("constructionType", response.data.constructionType);
            setFieldValue("dollarSize", response.data.dollarSize);
            setFieldValue("sector", response.data.sector);
            setFieldValue("scope", response.data.scope);
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        });
        setProjectBidStatus(MyBidStatus)
    } else {
      setIsEdit(false);
      resetForm();
      setFieldError("projectName", "Required!");
      setProjectBidStatus(PostBidStatus);
    }
  }, [projectId]);

  useEffect(() => {
    getProjectContact("", undefined, undefined)
      .then((response) => {
        setUserNameList([...response.data]);
      })
      .catch((error: any) => {});
  }, [projectId]);

  const handleFormSubmit = () => {
    if (isValid) {
      setTouched({
        bidDate: true,
        bidTime: true,
      });
    }
    const {
      projectName,
      projectNumber,
      buildingSize,
      status,
      siteSize,
      buildingCount,
      storyCount,
      dollarSize,
      duration,
      onlineAccess,
      buildingType,
      bidType,
      jobWalkDate,
      jobWalkTime,
      bidDate,
      bidTime,
      addressTwo,
      addressOne,
      state,
      zip,
      city,
      constructionType,
      sector,
      scope,
      projectContact,
    } = values;

    const body: any = {
      projectId,
      projectName:projectName.trim(),
      projectNumber,
      buildingSize,
      siteSize,
      buildingCount,
      storyCount,
      status,
      scope,
      dollarSize,
      duration,
      onlineAccess,
      buildingType,
      bidType,
      jobWalkDate,
      jobWalkTime,
      bidDate,
      bidTime,
      addressTwo,
      addressOne,
      state,
      zip,
      city,
      constructionType,
      sector : sector ? sector : null,
      projectContact:projectContact.id,
    };
      createProject("", undefined, body)
        .then((response) => {

          setShowsnackBar(true);
          if (response.status === "OK") {
            if (!projectId) {
              setMessage("Project Added Successfully");
              setTimeout(() => {
                setBackButton(false);
              }, 500);
            }else{
              setMessage("Project Updated Successfully");
            }
          } else {
            setMessage(response.message);
          }
        })
        .catch((error: any) => {
          console.log("error :>> ", error);
          setShowsnackBar(true);
          setMessage("Project save failed");
        });
  };

  const validateError = () => {
    if (
      projectErrors.projectName ||
      projectErrors.projectNumber ||
      projectErrors.state ||
      projectErrors.city ||
      projectErrors.zip ||
      projectErrors.sector ||
      projectErrors.constructionType ||
      projectErrors.budget ||
      projectErrors.bidDate ||
      projectErrors.bidTime
    ) {
      setTouched({
        projectName: true,
        projectNumber: true,
        state: true,
        city: true,
        zip: true,
        sector: true,
        constructionType: true,
        budget: true,
        bidDate: true,
        bidTime: true,
      });
      return false;
    }
    return true;
  };

  const fetchLocation = (zip: string) => {
    return new Promise<void>((resolve, reject) => {
      try {
        if (zip.length === 5) {
          fetch(GET_USA_STATE_AND_CITY + zip)
            .then((response) => {
              if (!response.ok) {
                throw new Error("Invalid Zip Code");
              }
              return response.json();
            })
            .then((json) => {
              setFieldValue("state", json["places"][0]["state abbreviation"]);
              setFieldValue("city", json["places"][0]["place name"]);
              setTimeout(() => {
                setTouched({ state: false });
              }, 0);
              resolve();
            })
            .catch((error: Error) => {
              setTouched({ zip: true });
              setTimeout(() => {
                setFieldError("zip", error.message);
              }, 0);
              setFieldValue("state", "");
              setFieldValue("city", "");
              reject(error);
            });
        } else {
          throw new Error("Must be exactly 5 digits");
        }
      } catch (error) {
        setFieldValue("state", "");
        setFieldValue("city", "");
        setTimeout(() => {
          setFieldError("zip", "Must be exactly 5 digits");
        }, 0);
        reject(error);
      }
    });
  };

  const handleFetchLocation = async (zip: string) => {
    try {
      await fetchLocation(zip);
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  return (
    <>
      <form className="form-group">
        <div className="row justify-content-center mt-3">
          <div className="col-5 col-md-5 col-sm-5">
            <InputField
              id="projectNumber"
              name="projectNumber"
              type="text"
              value={values.projectNumber.replace(/\D/g, "")}
              handleChange={handleChange}
              className="form-control"
              label="Project Number"
              readonly={isEdit}
              maxLength={6}
            />
          </div>
          <div className="col-5 col-md-5 col-sm-5">
            <FormControl fullWidth>
              <InputLabel
                id="select-label"
                size="small"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  fontFamily: "SenticosansDTCond Thin",
                }}
              >
                Project Status
              </InputLabel>
              <Select
                className="bg-white"
                labelId="select-label"
                id="status"
                name="status"
                value={values.status || ""}
                onChange={handleChange}
                size="small"
                label="Project Status "
              >
                <MenuItem value="" key={-1} className="d-block ms-3"></MenuItem>
                {projectBidStatus.map((status, index) => (
                  <MenuItem className="d-block ms-3" key={index} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
              {touched.sector && projectErrors.sector && (
                <span className="text-danger">{projectErrors.sector}</span>
              )}
            </FormControl>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-5 col-md-5 col-sm-5">
            <InputField
              id="projectName"
              name="projectName"
              type="text"
              value={values.projectName}
              handleChange={handleChange}
              className="form-control"
              label="Project Name"
              error={touched.projectName && projectErrors.projectName}
              required={!isEdit}
              readonly={isEdit}
            />
          </div>
          <div className="col-3 col-md-3 col-sm-3">
            <InputField
              id="buildingSize"
              name="buildingSize"
              type="text"
              value={values.buildingSize}
              handleChange={handleChange}
              className="form-control"
              label="Building SqFt"
              error={touched.buildingSize && projectErrors.buildingSize}
              readonly={isEdit}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="siteSqFt"
              name="siteSize"
              type="text"
              value={values.siteSize}
              handleChange={handleChange}
              className="form-control"
              label="Site SqFt"
              error={touched.siteSize && projectErrors.siteSize}
              readonly={isEdit}
            />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-3 col-md-3 col-sm-3 py-0">
            <DatePickerField
              id="bidDate"
              name="bidDate"
              value={values.bidDate}
              label="Bid Date"
              handleChange={handleChange}
              helperText={touched.bidDate ? projectErrors.bidDate : ""}
              error={touched.bidDate && projectErrors.bidDate ? true : false}
              required
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2 py-0">
            <TimePickerField
              id="bidTime"
              name="bidTime"
              value={values.bidTime}
              label="Bid Time"
              handleChange={handleChange}
              helperText={touched.bidTime ? projectErrors.bidTime : ""}
              error={touched.bidTime && projectErrors.bidTime ? true : false}
              required
            />
          </div>
          <div className="col-3 col-md-3 col-sm-3">
            <InputField
              id="buildingCount"
              name="buildingCount"
              type="text"
              value={values.buildingCount}
              handleChange={handleChange}
              className="form-control"
              label="Building Count"
              error={touched.buildingCount && projectErrors.buildingCount}
              shrink
              readonly={isEdit}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="storyCount"
              name="storyCount"
              type="text"
              value={values.storyCount}
              handleChange={handleChange}
              handleBlur={() => setTouched({ storyCount: true })}
              className="form-control"
              label="Stories"
              error={touched.storyCount && projectErrors.storyCount}
              minValue={0}
              shrink
              readonly={isEdit}
            />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-3 col-md-3 col-sm-3 py-0">
            <DatePickerField
              id="jobWalkDate"
              name="jobWalkDate"
              value={values.jobWalkDate}
              label="Job Walk Date"
              handleChange={handleChange}
              helperText={touched.jobWalkDate ? projectErrors.jobWalkDate : ""}
              error={
                touched.jobWalkDate && projectErrors.jobWalkDate ? true : false
              }
              readonly={isEdit}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2 py-0">
            <TimePickerField
              id="jobWalkTime"
              name="jobWalkTime"
              value={values.jobWalkTime}
              label="Job Walk Time"
              handleChange={handleChange}
              helperText={touched.jobWalkTime ? projectErrors.jobWalkTime : ""}
              error={
                touched.jobWalkTime && projectErrors.jobWalkTime ? true : false
              }
              readonly={isEdit}
            />
          </div>
          <div className="col-5 col-md-5 col-sm-5">
            <SelectInputField
              id="constructionType"
              name="constructionType"
              value={values.constructionType || ""}
              selectClassName="bg-white"
              menuClassName="d-block ms-3"
              handleChange={handleChange}
              label="Construction Type "
              menuList={ConstructionTypeList}
            />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-5 col-md-5 col-sm-5">
            <InputField
              id="dollarSize"
              name="dollarSize"
              type="text"
              value={values.dollarSize}
              handleChange={handleChange}
              className="form-control"
              label="Dollar Size"
              error={touched.dollarSize && projectErrors.dollarSize}
              readonly={isEdit}
            />
          </div>
          <div className="col-5 col-md-5 col-sm-5">
            <InputField
              id="duration"
              name="duration"
              type="text"
              value={values.duration}
              handleChange={handleChange}
              className="form-control"
              label="Duration"
              error={touched.duration && projectErrors.duration}
              readonly={isEdit}
            />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-5 col-md-5 col-sm-5">
            <InputField
              id="addressOne"
              name="addressOne"
              type="text"
              value={values.addressOne}
              handleChange={handleChange}
              className="form-control"
              label="Street"
              error={touched.addressOne && projectErrors.addressOne}
              readonly={isEdit}
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <SelectInputField
              id="sector"
              name="sector"
              value={values.sector || ""}
              selectClassName="bg-white"
              menuClassName="d-block ms-3"
              handleChange={handleChange}
              label="Sector"
              menuList={sectorList}
            />
          </div>
          <div className="col-3 col-md-3 col-sm-3">
            <SelectInputField
              id="onlineAccess"
              name="onlineAccess"
              value={values.onlineAccess || ""}
              selectClassName="bg-white"
              menuClassName="d-block ms-3"
              handleChange={handleChange}
              label="Online Access"
              menuList={accessStatus}
            />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-5 col-md-5 col-sm-5">
            <InputField
              id="city"
              name="city"
              type="text"
              value={values.city}
              handleChange={handleChange}
              className="form-control"
              label="City"
              error={touched.city && projectErrors.city}
              required
            />
          </div>
          <div className="col-5 col-md-5 col-sm-5">
            <FormControl fullWidth>
              <InputLabel
                id="select-label"
                size="small"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  fontFamily: "SenticosansDTCond Thin",
                }}
              >
                Building Type
              </InputLabel>
              <Select
                className="bg-white"
                labelId="select-label"
                id="buildingType"
                name="buildingType"
                value={values.buildingType || ""}
                onChange={handleChange}
                size="small"
                label="Building Type"
              >
                <MenuItem className="d-block ms-3" value="" key={-1}></MenuItem>
                {BuildingType.map((sector, index) => (
                  <MenuItem className="d-block ms-3" key={index} value={sector}>
                    {sector}
                  </MenuItem>
                ))}
              </Select>
              {touched.buildingType && projectErrors.buildingType && (
                <span className="text-danger">
                  {projectErrors.buildingType}
                </span>
              )}
            </FormControl>
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-3 col-md-3 col-sm-3">
            <InputField
              id="state"
              name="state"
              type="text"
              value={values.state.replace(/[^a-zA-Z!@# ]/g, "").toUpperCase()}
              handleChange={handleChange}
              className="form-control"
              label="State"
              error={touched.state && projectErrors.state}
              maxLength={2}
              required
            />
          </div>
          <div className="col-2 col-md-2 col-sm-2">
            <InputField
              id="zip"
              name="zip"
              type="text"
              value={values.zip}
              handleChange={(e: any) => {
                handleChange(e);
                handleFetchLocation(e.target.value);
              }}
              className="form-control"
              label="Zip"
              error={touched.zip && projectErrors.zip}
              required
              maxLength={5}
            />
          </div>
          <div className="col-5 col-md-5 col-sm-5">
            <FormControl fullWidth>
              <InputLabel
                id="select-label"
                size="small"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  fontFamily: "SenticosansDTCond Thin",
                }}
              >
                Bid Type
              </InputLabel>
              <Select
                className="bg-white"
                labelId="select-label"
                id="projectStatus"
                name="bidType"
                value={values.bidType || ""}
                onChange={handleChange}
                size="small"
                label="Bid Type "
              >
                <MenuItem className="d-block ms-3" value="" key={-1}></MenuItem>
                {BidType.map((sector, index) => (
                  <MenuItem className="d-block ms-3" key={index} value={sector}>
                    {sector}
                  </MenuItem>
                ))}
              </Select>
              {touched.bidType && projectErrors.bidType && (
                <span className="text-danger">{projectErrors.bidType}</span>
              )}
            </FormControl>
          </div>
        </div>
        <div className="row justify-content-center mt-1">
          <div className="col-1 col-md-1 col-sm-1">
            <p>Scope</p>
          </div>
          <div className="col-9 col-md-9 col-sm-9"></div>
        </div>
        <div
          className="row justify-content-center"
          style={{ marginTop: "-20px" }}
        >
          <div className="col-10 col-md-10 col-sm-10">
            <ReactQuill
              theme="snow"
              value={values.scope}
              onChange={(content) => setFieldValue("scope", content)}
              placeholder="Scope....."
            />
          </div>
        </div>
        <div className="row justify-content-center mt-3">
          <div className="col-5 col-md-5 col-sm-5">
            <FormControl fullWidth>
              <InputLabel
                id="select-label"
                size="small"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  fontSize: "14px",
                  fontFamily: "SenticosansDTCond Thin",
                }}
              >
                Project Contact
              </InputLabel>
              <Select
                className="bg-white"
                labelId="select-label"
                id="projectContact"
                name="projectContact"
                value={values.projectContact.firstName}
                size="small"
                label="Project Contact"
              >
                <MenuItem className="d-block ms-3" value="" key={-1}></MenuItem>
                {userNameList.map((contactName: any, index: number) => (
                  <MenuItem
                    id={contactName.id}
                    className="d-block ms-3"
                    key={index}
                    value={contactName.firstName}
                    onClick={(e: any) => {
                      console.log("value", e.target.value);
                      setFieldValue("projectContact", {
                        id: contactName.id,
                        firstName: contactName.firstName,
                      });
                    }}
                  >
                    {contactName.firstName}
                  </MenuItem>
                ))}
              </Select>
              {touched.projectContact && projectErrors.projectContact && (
                <span className="text-danger">
                  {projectErrors.projectContact.firstName}
                </span>
              )}
            </FormControl>
          </div>
          <div className="col-5 col-md-5 col-sm-5"></div>
        </div>
        {toShowButton && (
          <div
            className="row justify-content-center mt-3"
            style={{
              position: "sticky",
              bottom: "0",
              backgroundColor: "#f8f9fa",
              zIndex: "99",
            }}
          >
            <Button
              className="btn main-btn rounded col-3 col-md-3 col-sm-3  m-2 p-2"
              onClick={() => {
                if (validateError()) handleFormSubmit();
              }}
            >
              {isEdit ? "Update" : "Save"}
            </Button>
            <Button
              className="btn main-btn rounded col-3 col-md-3 col-sm-3  m-2 p-2"
              onClick={() => {
                setBackButton(false);
              }}
            >
              Back
            </Button>
          </div>
        )}
      </form>
      <CustomizedSnackbar
        openSnackBar={showSnackBar}
        message={message}
        handleCloseSnackBar={() => setShowsnackBar(false)}
        duration={6000}
      />
    </>
  );
};

export default BasicProjectDetailsForm;
