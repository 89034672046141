import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { selectProjectId } from "../../../redux/selectors/selectors";
import { getOrgClassification, getSubListForProject } from "../../../utils/api";
import SubListDropdown from "../SubListDropdown/subListDropdown";
import Loader from "../../Loader";
import { Button, Checkbox, FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from "@mui/material";
import CustomizedSnackbar from "../../CustomizedSnackbar";
import { DataUtil } from "../../../utils/dataUtils";
import SearchField from "../../SearchField/searchField";
import { BidStatusEnum } from "../../../utils/enum";
import { BidResponse, LaborAffilication } from "../../../utils/const";
import SelectInputField from "../../SelectInputField/selectInputField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilterCircleXmark, faSearch } from "@fortawesome/free-solid-svg-icons";
import { SearchFilteredSubListType } from "../../../utils/types";
import React from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const initialSearchFilteredSubList: SearchFilteredSubListType = {
  bidReposnse: [],
  laborAffiliation: "",
  classification: { id: null, classificationName: "", classificationCode: "" },
  isBidSubmitted: false,
};

interface ChildRef {
  refreshSubList: (message: string) => void;
}

interface Props {
  headerCells: any;
  projectName:string;
}

const ProjectSubList = forwardRef<ChildRef,Props>(({ headerCells,projectName }: Props,ref) => {
  const [result, setResult] = useState<any>([]);
  const [initailResult, setInitialResult] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showClearIcon, setShowClearIcon] = useState("none");
  const [searchItem, setSearchItem] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [expanded, setExpanded] = useState("");
  const [searchFilteredSubList,setSearchFilteredSubList] = useState<SearchFilteredSubListType>(initialSearchFilteredSubList);
  const [classificationList,setClassificationList] = useState([]);

  const projectId = useSelector(selectProjectId);
  
  useEffect(() => {
    setIsLoading(true);
    handleFetchSubListApi(initialSearchFilteredSubList);
    return () => {
      setRefresh(false);
    };
  }, [refresh, projectId]);

  useImperativeHandle(ref, () => ({
    refreshSubList,
  }));

  const refreshSubList = (message: string) => {
    setRefresh((prev) => !prev);
    setMessage(message);
    setIsOpen(true);
  };

  useEffect(() => {
    const fetchClassificationApi = () => {
      getOrgClassification("", "", undefined)
        .then((response) => {
          const data: any = [
            ...response.data.classification,
            { id: -1, classificationName: "", classificationCode: "" },
          ];
          console.log("data", data);
          setClassificationList(data);
        })
        .catch((error: any) => {
          console.log("error", error);
        })
        .finally(() => {});
    };
    fetchClassificationApi();
    return () => setClassificationList([]);
  }, []);

  const handleFetchSubListApi = ({ classification, laborAffiliation, bidReposnse, isBidSubmitted } : SearchFilteredSubListType) => {
    const classificationId = classification.id;
    getSubListForProject("", projectId, {classificationId,laborAffiliation,bidReposnse,isBidSubmitted})
      .then((resp) => {
        if (resp === null || resp.data === new Map()) {
          return;
        }
        setResult(resp.data.selectedSubs);
        setInitialResult(resp.data.selectedSubs);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const resetToInitialdNodes = () => setResult(initailResult);

  const searchToFilteredNodes = (newSearchItem: any) => {
    const removeSpaces = (str: string) => str.replace(/\s+/g, "");
    const isNumeric = /^[0-9\s]*$/.test(newSearchItem);
    const cleanedSearchItem = isNumeric
      ? removeSpaces(newSearchItem).toLowerCase()
      : newSearchItem.toLowerCase();

    const filteredNodes = initailResult.filter((item: any) => {
      const itemNameLower = item.name.toLowerCase();
      const cleanedTradeCode = removeSpaces(item.tradeCode).toLowerCase();
      return (
        itemNameLower.includes(cleanedSearchItem) ||
        cleanedTradeCode.includes(cleanedSearchItem)
      );
    });
    setResult(filteredNodes);
    setSearchItem(newSearchItem);
  };

const handleExpandChange =
(index: any) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  setExpanded(isExpanded ? index.toString() : "");
};

const handleChange = (
  event: SelectChangeEvent<typeof searchFilteredSubList.bidReposnse>
) => {
  const { value } = event.target;
  const bidMultiResponse = typeof value === "string" ? value.split(",") : value;
  setSearchFilteredSubList({
    ...searchFilteredSubList,
    bidReposnse: bidMultiResponse,
  });
};


  return (
    <>
      <div className="row gx-0 bg-white">
        <div className="col-4">
          <SearchField
            searchItem={searchItem}
            showSearchIconToField={false}
            showClearIconDisplay={showClearIcon}
            isLoading={isLoading}
            handleChange={(
              event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
            ) => {
              setShowClearIcon(event.target.value === "" ? "none" : "flex");
              searchToFilteredNodes(event.target.value);
            }}
            handleClickOnClear={() => {
              resetToInitialdNodes();
              setSearchItem("");
              setShowClearIcon("none");
            }}
          />
        </div>
        <div className="col-2">
          <FormControl fullWidth>
            <InputLabel
              id="select-label"
              size="small"
              className="bg-white size-sm"
            >
              Select Bid Response
            </InputLabel>
            <Select
              multiple
              labelId="select-label"
              id="Status"
              name="Status"
              label="Select Status"
              value={searchFilteredSubList.bidReposnse}
              className="bg-white w-auto"
              size="small"
              MenuProps={MenuProps}
              onChange={handleChange}
              input={<OutlinedInput label="Status" />}
            >
              {BidResponse.map((status: any, index: number) => (
                <MenuItem className="d-block px-3" key={index} value={status}>
                  {status}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-2">
          <SelectInputField
            id="laborAffiliation"
            name="laborAffiliation"
            label="Labor Affilication"
            value={searchFilteredSubList.laborAffiliation}
            labelClassName="bg-white"
            selectClassName="bg-white w-auto"
            menuClassName="d-block ms-3"
            menuList={LaborAffilication}
            handleChange={(event: any) => {
              setSearchFilteredSubList({
                ...searchFilteredSubList,
                laborAffiliation: event.target.value,
              });
            }}
          />
        </div>
        <div className="col-2">
          <SelectInputField
            id="classification"
            name="classification"
            label="Classification"
            value={searchFilteredSubList.classification.classificationCode}
            labelClassName="bg-white"
            selectClassName="bg-white w-auto"
            menuClassName="d-block ms-3"
            menuList={classificationList.map(
              (classification: any) => classification.classificationCode
            )}
            handleChange={(event: SelectChangeEvent<unknown>) => {
              let tempClassification: SearchFilteredSubListType["classification"][] =
                classificationList.filter(
                  (
                    classification: SearchFilteredSubListType["classification"]
                  ) =>
                    classification.classificationCode ===
                    (event.target.value as unknown as string)
                );
              setSearchFilteredSubList({
                ...searchFilteredSubList,
                classification: tempClassification[0],
              });
            }}
          />
        </div>
        <div className="col-2">
          <div className="mt-2 gap-1 d-flex justify-content-end">
            <Button
              className="btn"
              onClick={() => {
                setSearchFilteredSubList(initialSearchFilteredSubList);
                handleFetchSubListApi(initialSearchFilteredSubList);
              }}
              title="Clear"
              disabled={DataUtil.isObjectsAreEqual(
                searchFilteredSubList,
                initialSearchFilteredSubList
              )}
            >
              <FontAwesomeIcon icon={faFilterCircleXmark} size="lg" />
            </Button>
            <Button
              className="btn"
              title="Search"
              onClick={() => handleFetchSubListApi(searchFilteredSubList)}
              disabled={DataUtil.isObjectsAreEqual(
                searchFilteredSubList,
                initialSearchFilteredSubList
              )}
            >
              <FontAwesomeIcon icon={faSearch} size="lg" />
            </Button>
          </div>
        </div>
      </div>
      <div
        className="row gx-0 mt-3"
        style={{ maxHeight: "50vh", overflowY: "auto" }}
      >
        {!isLoading ? (
          DataUtil.isArrayAndNotEmpty(result) ? (
            result.map((node: any, index: any) => (
              <React.Fragment key={index}>
                <SubListDropdown
                  headerCells={headerCells}
                  node={node}
                  index={index}
                  refreshSubList={refreshSubList}
                  handleExpandChange={handleExpandChange}
                  expanded={expanded}
                  projectName={projectName}
                />
              </React.Fragment>
            ))
          ) : (
            <span className="d-flex opacity-70 justify-content-center mt-5 py-5 fs-2">
              No Trades Selected
            </span>
          )
        ) : (
          <Loader
            className="d-flex justify-content-center mt-5 py-5"
            label="Fetching Sub Contractor list"
          />
        )}
      </div>
      <CustomizedSnackbar
        openSnackBar={isOpen}
        message={message}
        handleCloseSnackBar={() => {
          setIsOpen(false);
        }}
      />
    </>
  );
});

export default ProjectSubList;
