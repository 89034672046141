import Tooltip from "@mui/material/Tooltip";

interface Props {
  children?: React.ReactNode;
  title: string;
  placement?:
    | "top"
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | undefined;
}

const CustomTooltipOverlay: React.FC<Props> = ({ children, title,placement="bottom" }: Props) => {
  return (
    <Tooltip title={title} placement={placement} arrow>
      <span>{children ? children : <></>}</span>
    </Tooltip>
  );
};

export default CustomTooltipOverlay;
