import { UserRoleEnum } from "../utils/enum";

const UsePermission = () => {
  const isOwner =
    localStorage.getItem("role") === UserRoleEnum.OWNER ||
    localStorage.getItem("role") === UserRoleEnum.OWNER_USER;
  const isSubContractor =
    localStorage.getItem("role") === UserRoleEnum.SC ||
    localStorage.getItem("role") === UserRoleEnum.SC_USER;

  return { isOwner, isSubContractor };
};

export default UsePermission;
